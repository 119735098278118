export function fanaticAnimationHomeMobile(cjs, an) {

    var p;
    var lib = {};
    var ss = {};
    var img = {};
    lib.ssMetadata = [];


    (lib.AnMovieClip = function () {
        this.actionFrames = [];
        this.gotoAndPlay = function (positionOrLabel) {
            cjs.MovieClip.prototype.gotoAndPlay.call(this, positionOrLabel);
        }
        this.play = function () {
            cjs.MovieClip.prototype.play.call(this);
        }
        this.gotoAndStop = function (positionOrLabel) {
            cjs.MovieClip.prototype.gotoAndStop.call(this, positionOrLabel);
        }
        this.stop = function () {
            cjs.MovieClip.prototype.stop.call(this);
        }
    }).prototype = p = new cjs.MovieClip();

    function mc_symbol_clone() {
        var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
        clone.gotoAndStop(this.currentFrame);
        clone.paused = this.paused;
        clone.framerate = this.framerate;
        return clone;
    }

    function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
        var prototype = cjs.extend(symbol, cjs.MovieClip);
        prototype.clone = mc_symbol_clone;
        prototype.nominalBounds = nominalBounds;
        prototype.frameBounds = frameBounds;
        return prototype;
    }


    (lib.T_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AhuGpIAAp9IjYAAIAAjUIKNAAIAADUIjYAAIAAJ9g");
        this.shape.setTransform(0.025, 0.025);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.T_1, new cjs.Rectangle(-32.7, -42.4, 65.5, 84.9), null);


    (lib.N_2 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("ADdGpIl0nAIAAHAIjeAAIAAtRICYAAIF1HCIAAnCIDeAAIAANRg");
        this.shape.setTransform(0.025, 0.025);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.N_2, new cjs.Rectangle(-37.4, -42.4, 74.9, 84.9), null);


    (lib.N_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("ADdGpIl0nAIAAHAIjeAAIAAtRICYAAIF1HCIAAnCIDeAAIAANRg");
        this.shape.setTransform(0.025, 0.025);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.N_1, new cjs.Rectangle(-37.4, -42.4, 74.9, 84.9), null);


    (lib.line_diag_2 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("Ae0RyMg9ngjj");
        this.shape.setTransform(0.025, 0);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.line_diag_2, new cjs.Rectangle(-198.1, -114.8, 396.29999999999995, 229.7), null);


    (lib.line_diag_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("Ae0xxMg9nAjj");
        this.shape.setTransform(0.025, 0);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.line_diag_1, new cjs.Rectangle(-198.1, -114.8, 396.29999999999995, 229.7), null);


    (lib.I_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AhuGpIAAtRIDdAAIAANRg");
        this.shape.setTransform(0, 0.025);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.I_1, new cjs.Rectangle(-11.1, -42.4, 22.299999999999997, 84.9), null);


    (lib.ClipGroup = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_2 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        mask.graphics.p("EhwfA+gMAAAh8/MDg/AAAMAAAB8/g");
        mask.setTransform(720, 400);

        // Layer_3
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#000000").s().p("AhkGeQhUggg9g6Qg7g5gjhUQgjhSAAhlQAAhkAkhUQAjhSA9g7QA+g7BQgfQBTggBaAAQBGAAA9AOQA6APAoASQAgANAbARIhwDGIgWgOQgcgNghgKQglgNgtAAQgwAAgpASQgpARgeAfQgeAegSAoQgSApAAAtQAAAtATAqQATAqAeAdQAgAeAqARQAoARAyAAQAxAAAmgMQAngNAYgPQALgGANgIIBvDFQgbASghAPQgoASg8AQQg4AQhMAAQhjAAhUgig");
        this.shape.setTransform(932.275, 398.75);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#000000").s().p("AhuGpIAAtRIDdAAIAANRg");
        this.shape_1.setTransform(871.25, 398.675);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#000000").s().p("AhuGpIAAp9IjYAAIAAjUIKNAAIAADUIjYAAIAAJ9g");
        this.shape_2.setTransform(814.075, 398.675);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#000000").s().p("ADdGpIl0nAIAAHAIjeAAIAAtRICYAAIF1HCIAAnCIDeAAIAANRg");
        this.shape_3.setTransform(653.775, 398.675);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#000000").s().p("AkYGpIAAtRIIxAAIAADUIlTAAIAACUIEHAAIAADEIkHAAIAAElg");
        this.shape_4.setTransform(499.125, 398.675);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#000000").s().p("ADbGpIg1iOIlLAAIg2COIjbAAIFAtRIDsAAIFBNRgABeBdIhej9IhfD9IC9AAg");
        this.shape_5.setTransform(566.3, 398.775);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#000000").s().p("ADcGpIg1iOIlMAAIg2COIjbAAIFAtRIDsAAIFBNRgABfBdIhfj9IheD9IC9AAg");
        this.shape_6.setTransform(742.05, 398.775);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("Ae0RyMg9ngjj");
        this.shape_7.setTransform(719.925, 399.95);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("Ae0xxMg9nAjj");
        this.shape_8.setTransform(720.425, 399.95);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("AX1AAMgvpAAA");
        this.shape_9.setTransform(720.15, 400);

        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("AAA30MAAAAvp");
        this.shape_10.setTransform(720.65, 399.5);

        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("AM5AAQAACphBCaQg+CVhyBzQhzBziUA/QiZBBioAAQinAAiZhBQiUg/hzhzQhyhzg+iVQhBiaAAipQAAioBBiaQA+iVByhyQBzhzCUg/QCZhCCnAAQCoAACZBCQCUA/BzBzQByByA+CVQBBCaAACog");
        this.shape_11.setTransform(720.15, 399.5);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("AXrAAQAAE1h3EaQhzERjSDTQjSDSkPBzQkZB4k1AAQkzAAkZh4QkQhzjSjSQjSjThzkRQh3kaAAk1QAAk0B3kaQBzkRDSjTQDSjSEQh0QEZh3EzAAQE1AAEZB3QEPB0DSDSQDSDTBzERQB3EaAAE0g");
        this.shape_12.setTransform(720.15, 399.5);

        var maskedShapeInstanceList = [this.shape, this.shape_1, this.shape_2, this.shape_3, this.shape_4, this.shape_5, this.shape_6, this.shape_7, this.shape_8, this.shape_9, this.shape_10, this.shape_11, this.shape_12];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.shape_12}, {t: this.shape_11}, {t: this.shape_10}, {t: this.shape_9}, {t: this.shape_8}, {t: this.shape_7}, {t: this.shape_6}, {t: this.shape_5}, {t: this.shape_4}, {t: this.shape_3}, {t: this.shape_2}, {t: this.shape_1}, {t: this.shape}]}).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.ClipGroup, new cjs.Rectangle(471, 246, 498.79999999999995, 307), null);


    (lib.F_2 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AkYGpIAAtRIIxAAIAADUIlTAAIAACUIEHAAIAADEIkHAAIAAElg");
        this.shape.setTransform(0.025, 0.025);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.F_2, new cjs.Rectangle(-28.1, -42.4, 56.3, 84.9), null);


    (lib.F_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AkYGpIAAtRIIxAAIAADUIlTAAIAACUIEHAAIAADEIkHAAIAAElg");
        this.shape.setTransform(0.025, 0.025);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.F_1, new cjs.Rectangle(-28.1, -42.4, 56.3, 84.9), null);


    (lib.C_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AhkGeQhUggg9g6Qg7g5gjhUQgjhSAAhlQAAhkAkhUQAjhSA9g7QA+g7BQgfQBTggBaAAQBGAAA9AOQA6APAoASQAgANAbARIhwDGIgWgOQgcgNghgKQglgNgtAAQgwAAgpASQgpARgeAfQgeAegSAoQgSApAAAtQAAAtATAqQATAqAeAdQAgAeAqARQAoARAyAAQAxAAAmgMQAngNAYgPQALgGANgIIBvDFQgbASghAPQgoASg8AQQg4AQhMAAQhjAAhUgig");
        this.shape.setTransform(0.025, 0);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.C_1, new cjs.Rectangle(-37.4, -44.7, 74.9, 89.5), null);


    (lib.A_2 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("ADbGpIg1iOIlLAAIg2COIjbAAIFAtRIDsAAIFBNRgABeBdIhej9IhfD9IC9AAg");
        this.shape.setTransform(0, 0.025);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.A_2, new cjs.Rectangle(-43.9, -42.4, 87.8, 84.9), null);


    (lib.A_1_2 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("ADcGpIg1iOIlMAAIg2COIjbAAIFAtRIDsAAIFBNRgABfBdIhfj9IheD9IC9AAg");
        this.shape.setTransform(0, 0.025);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.A_1_2, new cjs.Rectangle(-43.9, -42.4, 87.8, 84.9), null);


    (lib.A_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("ADbGpIg1iOIlLAAIg2COIjbAAIFAtRIDsAAIFBNRgABeBdIhej9IhfD9IC9AAg");
        this.shape.setTransform(0, 0.025);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

    }).prototype = getMCSymbolPrototype(lib.A_1, new cjs.Rectangle(-43.9, -42.4, 87.8, 84.9), null);


    (lib.Tween1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_1
        this.instance = new lib.A_1_2();
        this.instance.setTransform(0, 65.85);

        this.instance_1 = new lib.T_1();
        this.instance_1.setTransform(-1.15, -65.9);

        this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.instance_1}, {t: this.instance}]}).wait(1));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-43.9, -108.3, 87.8, 216.7);


    (lib.main = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // timeline functions:
        this.frame_113 = function () {
            this.stop()
        }

        // actions tween:
        this.timeline.addTween(cjs.Tween.get(this).wait(113).call(this.frame_113).wait(1));

        // Layer_2 (mask)
        var mask = new cjs.Shape();
        mask._off = true;
        var mask_graphics_96 = new cjs.Graphics().p("AipCpQhGhGAAhjQAAhiBGhHQBHhGBiAAQBjAABGBGQBHBHAABiQAABjhHBGQhGBHhjAAQhiAAhHhHg");
        var mask_graphics_97 = new cjs.Graphics().p("AqAJzQkIkDAAlwQAAlvEIkFQEKkDF2AAQF3AAEIEDQEKEFAAFvQAAFwkKEDQkIEFl3AAQl2AAkKkFg");
        var mask_graphics_98 = new cjs.Graphics().p("A2bV5QpQpDAAs2QAAs1JQpHQJTpDNIAAQNJAAJPJDQJUJHAAM1QAAM2pUJDQpPJHtJAAQtIAApTpHg");
        var mask_graphics_99 = new cjs.Graphics().p("EgrvAqnQyBxmAA5BQAA5ASBxuQSJxnZmAAQZnAASBRnQSJRuAAZAQAAZByJRmQyBRv5nAAQ5mAAyJxvg");
        var mask_graphics_100 = new cjs.Graphics().p("Eg9KA7kUgZMgYmAAAgi+UAAAgi9AZMgYxUAZXgYnAjzAAAUAj0AAAAZMAYnUAZXAYxAAAAi9UAAAAi+gZXAYmUgZMAYygj0AAAUgjzAAAgZXgYyg");
        var mask_graphics_101 = new cjs.Graphics().p("EhG1BE+UgdKgcfAAAgofUAAAgoeAdKgcsUAdYgceApdAAAUApeAAAAdKAceUAdYAcsAAAAoeUAAAAofgdYAcfUgdKAcrgpeAAAUgpdAAAgdYgcrg");
        var mask_graphics_102 = new cjs.Graphics().p("EhM5BK4Ugfrge7AAAgr9UAAAgr8AfrgfJUAf5ge7AtAAAAUAtBAAAAfrAe7UAf5AfJAAAAr8UAAAAr9gf5Ae7UgfrAfJgtBAAAUgtAAAAgf5gfJg");
        var mask_graphics_103 = new cjs.Graphics().p("EhRFBO8UghZggnAAAguVUAAAguUAhZgg2UAhoggmAvdAAAUAvdAAAAhZAgmUAhpAg2AAAAuUUAAAAuVghpAgnUghZAg1gvdAAAUgvdAAAghogg1g");
        var mask_graphics_104 = new cjs.Graphics().p("EhUFBR3Ugiogh0AAAgwDUAAAgwCAiogiDUAi4gh0AxNAAAUAxOAAAAioAh0UAi4AiDAAAAwCUAAAAwDgi4Ah0UgioAiDgxOAAAUgxNAAAgi4giDg");
        var mask_graphics_105 = new cjs.Graphics().p("EhWSBUAUgjigisAAAgxUUAAAgxTAjigi8UAjygisAygAAAUAyhAAAAjiAisUAjyAi8AAAAxTUAAAAxUgjyAisUgjiAi8gyhAAAUgygAAAgjygi8g");
        var mask_graphics_106 = new cjs.Graphics().p("EhX6BVlUgkNgjWAAAgyPUAAAgyOAkNgjmUAkdgjWAzdAAAUAzeAAAAkNAjWUAkdAjmAAAAyOUAAAAyPgkdAjWUgkNAjmgzeAAAUgzdAAAgkdgjmg");
        var mask_graphics_107 = new cjs.Graphics().p("EhZFBWuUgksgj0AAAgy6UAAAgy5AksgkFUAk8gj0A0JAAAUA0KAAAAksAj0UAk8AkFAAAAy5UAAAAy6gk8Aj0UgksAkFg0KAAAUg0JAAAgk8gkFg");
        var mask_graphics_108 = new cjs.Graphics().p("EhZ6BXiUglCgkKAAAgzYUAAAgzXAlCgkaUAlTgkJA0nAAAUA0oAAAAlCAkJUAlTAkaAAAAzXUAAAAzYglTAkKUglCAkZg0oAAAUg0nAAAglTgkZg");
        var mask_graphics_109 = new cjs.Graphics().p("EhacBYDUglQgkXAAAgzsUAAAgzrAlQgknUAlhgkYA07AAAUA08AAAAlQAkYUAlhAknAAAAzrUAAAAzsglhAkXUglQAkog08AAAUg07AAAglhgkog");
        var mask_graphics_110 = new cjs.Graphics().p("EhavBYVUglYgkeAAAgz3UAAAgz2AlYgkvUAlpgkfA1GAAAUA1HAAAAlYAkfUAlpAkvAAAAz2UAAAAz3glpAkeUglYAkwg1HAAAUg1GAAAglpgkwg");
        var mask_graphics_111 = new cjs.Graphics().p("Eha1BYbUglagkhAAAgz6UAAAgz5AlagkyUAlrgkhA1KAAAUA1LAAAAlaAkhUAlrAkyAAAAz5UAAAAz6glrAkhUglaAkyg1LAAAUg1KAAAglrgkyg");

        this.timeline.addTween(cjs.Tween.get(mask).to({
            graphics: null,
            x: 0,
            y: 0
        }).wait(96).to({graphics: mask_graphics_96, x: 0, y: 0}).wait(1).to({
            graphics: mask_graphics_97,
            x: 0,
            y: 0.3042
        }).wait(1).to({graphics: mask_graphics_98, x: 0, y: 0.8181}).wait(1).to({
            graphics: mask_graphics_99,
            x: 0,
            y: 1.699
        }).wait(1).to({graphics: mask_graphics_100, x: 0, y: 2.4196}).wait(1).to({
            graphics: mask_graphics_101,
            x: 0,
            y: 2.8192
        }).wait(1).to({graphics: mask_graphics_102, x: 0, y: 3.0704}).wait(1).to({
            graphics: mask_graphics_103,
            x: 0,
            y: 3.2431
        }).wait(1).to({graphics: mask_graphics_104, x: 0, y: 3.3674}).wait(1).to({
            graphics: mask_graphics_105,
            x: 0,
            y: 3.4586
        }).wait(1).to({graphics: mask_graphics_106, x: 0, y: 3.5257}).wait(1).to({
            graphics: mask_graphics_107,
            x: 0,
            y: 3.5743
        }).wait(1).to({graphics: mask_graphics_108, x: 0, y: 3.6083}).wait(1).to({
            graphics: mask_graphics_109,
            x: 0,
            y: 3.6305
        }).wait(1).to({graphics: mask_graphics_110, x: 0, y: 3.6426}).wait(1).to({
            graphics: mask_graphics_111,
            x: 0,
            y: -4.7
        }).wait(3));

        // Layer_1
        this.instance = new lib.ClipGroup();
        this.instance.setTransform(1.6, 0, 1, 1, 0, 0, 0, 720, 400);

        this.shape = new cjs.Shape();
        this.shape.graphics.f("#F2F3F5").s().p("EhwfB95MAAAj7xMDg/AAAMAAAD7xg");
        this.shape.setTransform(1.6, 0);

        var maskedShapeInstanceList = [this.instance, this.shape];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask;
        }

        this.timeline.addTween(cjs.Tween.get({}).to({state: []}).to({state: [{t: this.shape}, {t: this.instance}]}, 96).wait(18));

        // MSK (mask)
        var mask_1 = new cjs.Shape();
        mask_1._off = true;
        var mask_1_graphics_0 = new cjs.Graphics().p("Eg6/AGwIAAtfMB1/AAAIAANfg");
        var mask_1_graphics_58 = new cjs.Graphics().p("Eg6/AIAIAAv/MB1/AAAIAAP/g");

        this.timeline.addTween(cjs.Tween.get(mask_1).to({
            graphics: mask_1_graphics_0,
            x: 6.4,
            y: -1.6
        }).wait(58).to({graphics: mask_1_graphics_58, x: 6.4, y: -1.55}).wait(56));

        // C
        this.instance_1 = new lib.C_1();
        this.instance_1.setTransform(212.25, -97.25);
        this.instance_1._off = true;

        var maskedShapeInstanceList = [this.instance_1];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(43).to({_off: false}, 0).wait(1).to({y: -86.5572}, 0).wait(1).to({y: -65.1057}, 0).wait(1).to({y: -37.2482}, 0).wait(1).to({y: -23.0283}, 0).wait(1).to({y: -15.1264}, 0).wait(1).to({y: -10.1046}, 0).wait(1).to({y: -6.7294}, 0).wait(1).to({y: -4.4292}, 0).wait(1).to({y: -2.8915}, 0).wait(1).to({y: -1.926}, 0).wait(1).to({y: -1.4078}, 0).wait(1).to({y: -1.25}, 0).to({_off: true}, 41).wait(18));

        // msk (mask)
        var mask_2 = new cjs.Shape();
        mask_2._off = true;
        mask_2.graphics.p("Eg6/AGwIAAtfMB1/AAAIAANfg");
        mask_2.setTransform(6.4, -1.6);

        // I
        this.instance_2 = new lib.I_1();
        this.instance_2.setTransform(151.25, -89.35);
        this.instance_2._off = true;

        var maskedShapeInstanceList = [this.instance_2];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(37).to({_off: false}, 0).wait(1).to({y: -80.5333}, 0).wait(1).to({y: -63.9551}, 0).wait(1).to({y: -38.998}, 0).wait(1).to({y: -24.4453}, 0).wait(1).to({y: -16.4442}, 0).wait(1).to({y: -11.3569}, 0).wait(1).to({y: -7.8908}, 0).wait(1).to({y: -5.4642}, 0).wait(1).to({y: -3.7658}, 0).wait(1).to({y: -2.6088}, 0).wait(1).to({y: -1.8722}, 0).wait(1).to({y: -1.4726}, 0).wait(1).to({y: -1.35}, 0).to({_off: true}, 46).wait(18));

        // MSK (mask)
        var mask_3 = new cjs.Shape();
        mask_3._off = true;
        mask_3.graphics.p("Eg6/AGwIAAtfMB1/AAAIAANfg");
        mask_3.setTransform(6.4, -1.6);

        // T
        this.instance_3 = new lib.Tween1("synched", 0);
        this.instance_3.setTransform(95.2, -151.45);
        this.instance_3._off = true;

        var maskedShapeInstanceList = [this.instance_3];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_3;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(24).to({_off: false}, 0).wait(1).to({y: -140.6465}, 0).wait(1).to({y: -125.9669}, 0).wait(1).to({y: -104.7375}, 0).wait(1).to({y: -73.1062}, 0).wait(1).to({y: -37.1281}, 0).wait(1).to({y: -10.8702}, 0).wait(1).to({y: 6.7481}, 0).wait(1).to({y: 19.3273}, 0).wait(1).to({y: 28.8043}, 0).wait(1).to({y: 36.2023}, 0).wait(1).to({y: 42.1109}, 0).wait(1).to({y: 46.8981}, 0).wait(1).to({y: 50.8084}, 0).wait(1).to({y: 54.0127}, 0).wait(1).to({y: 56.6352}, 0).wait(1).to({y: 58.7689}, 0).wait(1).to({y: 60.4857}, 0).wait(1).to({y: 61.8421}, 0).wait(1).to({y: 62.8834}, 0).wait(1).to({y: 63.6466}, 0).wait(1).to({y: 64.1624}, 0).wait(1).to({y: 64.4563}, 0).wait(1).to({y: 64.55}, 0).to({_off: true}, 49).wait(18));

        // msk (mask)
        var mask_4 = new cjs.Shape();
        mask_4._off = true;
        mask_4.graphics.p("Eg6/AGwIAAtfMB1/AAAIAANfg");
        mask_4.setTransform(6.4, -1.6);

        // A
        this.instance_4 = new lib.A_1_2();
        this.instance_4.setTransform(22.05, 89.95);
        this.instance_4._off = true;

        var maskedShapeInstanceList = [this.instance_4];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_4;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(44).to({_off: false}, 0).wait(1).to({y: 82.3409}, 0).wait(1).to({y: 69.4879}, 0).wait(1).to({y: 47.4558}, 0).wait(1).to({y: 29.4328}, 0).wait(1).to({y: 19.4393}, 0).wait(1).to({y: 13.1575}, 0).wait(1).to({y: 8.838}, 0).wait(1).to({y: 5.73}, 0).wait(1).to({y: 3.4486}, 0).wait(1).to({y: 1.7702}, 0).wait(1).to({y: 0.5534}, 0).wait(1).to({y: -0.2975}, 0).wait(1).to({y: -0.8505}, 0).wait(1).to({y: -1.1553}, 0).wait(1).to({y: -1.25}, 0).to({_off: true}, 37).wait(18));

        // mask_idn (mask)
        var mask_5 = new cjs.Shape();
        mask_5._off = true;
        mask_5.graphics.p("Eg6/AIyIAAxjMB1/AAAIAARjg");
        mask_5.setTransform(6.4, 97.45);

        // N
        this.instance_5 = new lib.N_1();
        this.instance_5.setTransform(-66.25, 216.25);
        this.instance_5._off = true;

        var maskedShapeInstanceList = [this.instance_5];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(14).to({_off: false}, 0).wait(1).to({y: 208.9958}, 0).wait(1).to({y: 200.1171}, 0).wait(1).to({y: 188.9621}, 0).wait(1).to({y: 174.4827}, 0).wait(1).to({y: 155.1786}, 0).wait(1).to({y: 130.5171}, 0).wait(1).to({y: 105.0732}, 0).wait(1).to({y: 84.5209}, 0).wait(1).to({y: 69.113}, 0).wait(1).to({y: 57.3299}, 0).wait(1).to({y: 48.0141}, 0).wait(1).to({y: 40.4418}, 0).wait(1).to({y: 34.1567}, 0).wait(1).to({y: 28.8589}, 0).wait(1).to({y: 24.3421}, 0).wait(1).to({y: 20.4592}, 0).wait(1).to({y: 17.1016}, 0).wait(1).to({y: 14.1868}, 0).wait(1).to({y: 11.6509}, 0).wait(1).to({y: 9.4432}, 0).wait(1).to({y: 7.5228}, 0).wait(1).to({y: 5.8562}, 0).wait(1).to({y: 4.4157}, 0).wait(1).to({y: 3.1779}, 0).wait(1).to({y: 2.1232}, 0).wait(1).to({y: 1.2347}, 0).wait(1).to({y: 0.4979}, 0).wait(1).to({y: -0.1}, 0).wait(1).to({y: -0.57}, 0).wait(1).to({y: -0.9219}, 0).wait(1).to({y: -1.1642}, 0).wait(1).to({y: -1.3046}, 0).wait(1).to({y: -1.35}, 0).to({_off: true}, 1).wait(66));

        // A
        this.instance_6 = new lib.A_1();
        this.instance_6.setTransform(-153.7, 216.35);
        this.instance_6._off = true;

        var maskedShapeInstanceList = [this.instance_6];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(6).to({_off: false}, 0).wait(1).to({y: 206.4909}, 0).wait(1).to({y: 194.5037}, 0).wait(1).to({y: 179.5702}, 0).wait(1).to({y: 160.3913}, 0).wait(1).to({y: 135.0711}, 0).wait(1).to({y: 102.4372}, 0).wait(1).to({y: 67.1506}, 0).wait(1).to({y: 37.4166}, 0).wait(1).to({y: 14.8574}, 0).wait(1).to({y: -2.3908}, 0).wait(1).to({y: -15.9972}, 0).wait(1).to({y: -27.0374}, 0).wait(1).to({y: -36.192}, 0).wait(1).to({y: -43.9077}, 0).wait(1).to({y: -50.4896}, 0).wait(1).to({y: -56.1549}, 0).wait(1).to({y: -61.0629}, 0).wait(1).to({y: -65.3342}, 0).wait(1).to({y: -69.0621}, 0).wait(1).to({y: -72.3203}, 0).wait(1).to({y: -75.168}, 0).wait(1).to({y: -77.6536}, 0).wait(1).to({y: -79.8171}, 0).wait(1).to({y: -81.6921}, 0).wait(1).to({y: -83.307}, 0).wait(1).to({y: -84.6859}, 0).wait(1).to({y: -85.8498}, 0).wait(1).to({y: -86.8167}, 0).wait(1).to({y: -87.6025}, 0).wait(1).to({y: -88.2211}, 0).wait(1).to({y: -88.6848}, 0).wait(1).to({y: -89.0045}, 0).wait(1).to({y: -89.19}, 0).wait(1).to({y: -89.25}, 0).to({_off: true}, 1).wait(73));

        // F
        this.instance_7 = new lib.F_1();
        this.instance_7.setTransform(-220.9, 216.25);

        var maskedShapeInstanceList = [this.instance_7];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(1).to({y: 203.1285}, 0).wait(1).to({y: 187.0685}, 0).wait(1).to({y: 166.8909}, 0).wait(1).to({y: 140.7003}, 0).wait(1).to({y: 105.7826}, 0).wait(1).to({y: 61.1744}, 0).wait(1).to({y: 15.1508}, 0).wait(1).to({y: -22.0248}, 0).wait(1).to({y: -49.8949}, 0).wait(1).to({y: -71.2085}, 0).wait(1).to({y: -88.0591}, 0).wait(1).to({y: -101.7561}, 0).wait(1).to({y: -113.1246}, 0).wait(1).to({y: -122.7075}, 0).wait(1).to({y: -130.8775}, 0).wait(1).to({y: -137.901}, 0).wait(1).to({y: -143.9744}, 0).wait(1).to({y: -149.2467}, 0).wait(1).to({y: -153.8336}, 0).wait(1).to({y: -157.827}, 0).wait(1).to({y: -161.3007}, 0).wait(1).to({y: -164.3153}, 0).wait(1).to({y: -166.9209}, 0).wait(1).to({y: -169.1598}, 0).wait(1).to({y: -171.0675}, 0).wait(1).to({y: -172.6746}, 0).wait(1).to({y: -174.0075}, 0).wait(1).to({y: -175.089}, 0).wait(1).to({y: -175.9392}, 0).wait(1).to({y: -176.5756}, 0).wait(1).to({y: -177.0139}, 0).wait(1).to({y: -177.2679}, 0).wait(1).to({y: -177.35}, 0).to({_off: true}, 1).wait(80));

        // MSK (mask)
        var mask_6 = new cjs.Shape();
        mask_6._off = true;
        mask_6.graphics.p("Eg6/AGwIAAtfMB1/AAAIAANfg");
        mask_6.setTransform(6.4, -1.6);

        // N_2
        this.instance_8 = new lib.N_2();
        this.instance_8.setTransform(-66.25, 86.65);
        this.instance_8._off = true;

        var maskedShapeInstanceList = [this.instance_8];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_6;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(49).to({_off: false}, 0).wait(1).to({y: 79.8741}, 0).wait(1).to({y: 68.903}, 0).wait(1).to({y: 49.9797}, 0).wait(1).to({y: 31.6484}, 0).wait(1).to({y: 21.1129}, 0).wait(1).to({y: 14.5445}, 0).wait(1).to({y: 10.0327}, 0).wait(1).to({y: 6.7667}, 0).wait(1).to({y: 4.339}, 0).wait(1).to({y: 2.5168}, 0).wait(1).to({y: 1.1545}, 0).wait(1).to({y: 0.1547}, 0).wait(1).to({y: -0.5513}, 0).wait(1).to({y: -1.0135}, 0).wait(1).to({y: -1.27}, 0).wait(1).to({y: -1.35}, 0).to({_off: true}, 31).wait(18));

        // MSK (mask)
        var mask_7 = new cjs.Shape();
        mask_7._off = true;
        mask_7.graphics.p("Eg6/AGwIAAtfMB1/AAAIAANfg");
        mask_7.setTransform(6.4, -1.6);

        // A_2
        this.instance_9 = new lib.A_2();
        this.instance_9.setTransform(-153.7, 86.75);
        this.instance_9._off = true;

        var maskedShapeInstanceList = [this.instance_9];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_7;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_9).wait(53).to({_off: false}, 0).wait(1).to({y: 79.9741}, 0).wait(1).to({y: 69.003}, 0).wait(1).to({y: 50.0797}, 0).wait(1).to({y: 31.7484}, 0).wait(1).to({y: 21.2129}, 0).wait(1).to({y: 14.6445}, 0).wait(1).to({y: 10.1327}, 0).wait(1).to({y: 6.8667}, 0).wait(1).to({y: 4.439}, 0).wait(1).to({y: 2.6168}, 0).wait(1).to({y: 1.2545}, 0).wait(1).to({y: 0.2547}, 0).wait(1).to({y: -0.4513}, 0).wait(1).to({y: -0.9135}, 0).wait(1).to({y: -1.17}, 0).wait(1).to({y: -1.25}, 0).to({_off: true}, 27).wait(18));

        // MSK (mask)
        var mask_8 = new cjs.Shape();
        mask_8._off = true;
        mask_8.graphics.p("Eg6/AGwIAAtfMB1/AAAIAANfg");
        mask_8.setTransform(6.4, -1.6);

        // F_2
        this.instance_10 = new lib.F_2();
        this.instance_10.setTransform(-220.9, 86.65);
        this.instance_10._off = true;

        var maskedShapeInstanceList = [this.instance_10];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_8;
        }

        this.timeline.addTween(cjs.Tween.get(this.instance_10).wait(58).to({_off: false}, 0).wait(1).to({y: 79.8741}, 0).wait(1).to({y: 68.903}, 0).wait(1).to({y: 49.9797}, 0).wait(1).to({y: 31.6484}, 0).wait(1).to({y: 21.1129}, 0).wait(1).to({y: 14.5445}, 0).wait(1).to({y: 10.0327}, 0).wait(1).to({y: 6.7667}, 0).wait(1).to({y: 4.339}, 0).wait(1).to({y: 2.5168}, 0).wait(1).to({y: 1.1545}, 0).wait(1).to({y: 0.1547}, 0).wait(1).to({y: -0.5513}, 0).wait(1).to({y: -1.0135}, 0).wait(1).to({y: -1.27}, 0).wait(1).to({y: -1.35}, 0).to({_off: true}, 22).wait(18));

        // logo
        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#FFFFFF").s().p("AhkGeQhUggg9g6Qg7g5gjhUQgjhSAAhlQAAhkAkhUQAjhSA9g7QA+g7BQgfQBTggBaAAQBGAAA9AOQA6APAoASQAgANAbARIhwDGIgWgOQgcgNghgKQglgNgtAAQgwAAgpASQgpARgeAfQgeAegSAoQgSApAAAtQAAAtATAqQATAqAeAdQAgAeAqARQAoARAyAAQAxAAAmgMQAngNAYgPQALgGANgIIBvDFQgbASghAPQgoASg8AQQg4AQhMAAQhjAAhUgig");
        this.shape_1.setTransform(212.275, -1.25);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("AhuGpIAAtRIDdAAIAANRg");
        this.shape_2.setTransform(151.25, -1.325);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#FFFFFF").s().p("AhuGpIAAp9IjYAAIAAjUIKNAAIAADUIjYAAIAAJ9g");
        this.shape_3.setTransform(94.075, -1.325);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("ADcGpIg1iOIlMAAIg2COIjbAAIFAtRIDsAAIFBNRgABfBdIhfj9IheD9IC9AAg");
        this.shape_4.setTransform(22.05, -1.225);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#FFFFFF").s().p("ADdGpIl0nAIAAHAIjeAAIAAtRICYAAIF1HCIAAnCIDeAAIAANRg");
        this.shape_5.setTransform(-66.225, -1.325);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f("#FFFFFF").s().p("ADbGpIg1iOIlLAAIg2COIjbAAIFAtRIDsAAIFBNRgABeBdIhej9IhfD9IC9AAg");
        this.shape_6.setTransform(-153.7, -1.225);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f("#FFFFFF").s().p("AkYGpIAAtRIIxAAIAADUIlTAAIAACUIEHAAIAADEIkHAAIAAElg");
        this.shape_7.setTransform(-220.875, -1.325);

        this.timeline.addTween(cjs.Tween.get({}).to({state: []}).to({state: [{t: this.shape_7}, {t: this.shape_6}, {t: this.shape_5}, {t: this.shape_4}, {t: this.shape_3}, {t: this.shape_2}, {t: this.shape_1}]}, 96).wait(18));

        // Line_2
        this.instance_11 = new lib.line_diag_2();
        this.instance_11.setTransform(-0.1, -0.1, 0.675, 0.675, -29.858);
        this.instance_11._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_11).wait(33).to({_off: false}, 0).to({
            scaleX: 0.9999,
            scaleY: 0.9999,
            rotation: -30.2247
        }, 8).wait(1).to({
            scaleX: 1,
            scaleY: 1,
            rotation: -15.148,
            x: -0.0597,
            y: -0.1356
        }, 0).wait(1).to({rotation: 17.143, x: 0.0285, y: -0.2004}, 0).wait(1).to({
            rotation: 51.069,
            x: 0.1161,
            y: -0.2527
        }, 0).wait(1).to({rotation: 66.9099, x: 0.1528, y: -0.2729}, 0).wait(1).to({
            rotation: 75.805,
            x: 0.1719,
            y: -0.2835
        }, 0).wait(1).to({rotation: 81.4159, x: 0.1834, y: -0.2901}, 0).wait(1).to({
            rotation: 85.0978,
            x: 0.1906,
            y: -0.2944
        }, 0).wait(1).to({rotation: 87.4983, x: 0.1953, y: -0.2971}, 0).wait(1).to({
            rotation: 88.979,
            x: 0.1981,
            y: -0.2988
        }, 0).wait(1).to({rotation: 89.7635, x: 0.1996, y: -0.2997}, 0).wait(1).to({
            rotation: 90,
            x: 0.15,
            y: -0.3
        }, 0).wait(1).to({rotation: 90.1479, x: 0.1504, y: -0.2996}, 0).wait(1).to({
            rotation: 90.6338,
            x: 0.1518,
            y: -0.2982
        }, 0).wait(1).to({rotation: 91.5389, x: 0.1543, y: -0.2957}, 0).wait(1).to({
            rotation: 92.9805,
            x: 0.1583,
            y: -0.2917
        }, 0).wait(1).to({rotation: 95.1369, x: 0.1643, y: -0.2857}, 0).wait(1).to({
            rotation: 98.3012,
            x: 0.1731,
            y: -0.2769
        }, 0).wait(1).to({rotation: 103.0092, x: 0.1861, y: -0.2639}, 0).wait(1).to({
            rotation: 110.4171,
            x: 0.2067,
            y: -0.2433
        }, 0).wait(1).to({rotation: 123.7483, x: 0.2437, y: -0.2063}, 0).wait(1).to({
            rotation: 149.8647,
            x: 0.3163,
            y: -0.1337
        }, 0).wait(1).to({rotation: 169.9755, x: 0.3722, y: -0.0778}, 0).wait(1).to({
            rotation: 180,
            x: 0.4,
            y: -0.05
        }, 0).to({_off: true}, 32).wait(18));

        // line_1
        this.instance_12 = new lib.line_diag_1();
        this.instance_12.setTransform(0.45, 0, 0.6762, 0.6762, 29.9983, 0, 0, 0.1, 0.1);
        this.instance_12._off = true;

        this.timeline.addTween(cjs.Tween.get(this.instance_12).wait(33).to({_off: false}, 0).to({
            regY: -0.1,
            scaleX: 0.9999,
            scaleY: 0.9999,
            rotation: 30.2247,
            x: 0.5,
            y: -0.1
        }, 8).wait(1).to({regX: 0, regY: 0, rotation: 37.7207, x: 0.3025, y: -0.0408}, 0).wait(1).to({
            scaleX: 1,
            scaleY: 1,
            rotation: 53.7757,
            x: 0.2109,
            y: -0.02
        }, 0).wait(1).to({rotation: 70.6436, x: 0.129, y: 0.0071}, 0).wait(1).to({
            rotation: 78.5197,
            x: 0.0951,
            y: 0.0228
        }, 0).wait(1).to({rotation: 82.9423, x: 0.0772, y: 0.0326}, 0).wait(1).to({
            rotation: 85.732,
            x: 0.0662,
            y: 0.0392
        }, 0).wait(1).to({rotation: 87.5626, x: 0.0592, y: 0.0438}, 0).wait(1).to({
            rotation: 88.7562,
            x: 0.0547,
            y: 0.0468
        }, 0).wait(1).to({rotation: 89.4924, x: 0.0519, y: 0.0487}, 0).wait(1).to({
            rotation: 89.8824,
            x: 0.0504,
            y: 0.0497
        }, 0).wait(1).to({rotation: 90, x: 0.15, y: 0.2}, 0).wait(1).to({
            rotation: 90.1479,
            x: 0.1496,
            y: 0.1996
        }, 0).wait(1).to({rotation: 90.6338, x: 0.1482, y: 0.1982}, 0).wait(1).to({
            rotation: 91.5389,
            x: 0.1457,
            y: 0.1957
        }, 0).wait(1).to({rotation: 92.9805, x: 0.1417, y: 0.1917}, 0).wait(1).to({
            rotation: 95.1369,
            x: 0.1357,
            y: 0.1857
        }, 0).wait(1).to({rotation: 98.3012, x: 0.1269, y: 0.1769}, 0).wait(1).to({
            rotation: 103.0092,
            x: 0.1139,
            y: 0.1639
        }, 0).wait(1).to({rotation: 110.4171, x: 0.0933, y: 0.1433}, 0).wait(1).to({
            rotation: 123.7483,
            x: 0.0563,
            y: 0.1063
        }, 0).wait(1).to({rotation: 149.8647, x: -0.0163, y: 0.0337}, 0).wait(1).to({
            rotation: 169.9755,
            x: -0.0722,
            y: -0.0222
        }, 0).wait(1).to({rotation: 180, x: -0.1, y: -0.05}, 0).to({_off: true}, 32).wait(18));

        // mask_line (mask)
        var mask_9 = new cjs.Shape();
        mask_9._off = true;
        var mask_9_graphics_27 = new cjs.Graphics().p("AhoBpQgsgrAAg+QAAg9AsgrQArgsA9AAQA+AAArAsQAsArAAA9QAAA+gsArQgrAsg+AAQg9AAgrgsg");
        var mask_9_graphics_28 = new cjs.Graphics().p("Ai6C7QhNhOAAhtQAAhsBNhOQBOhNBsAAQBtAABOBNQBNBOAABsQAABthNBOQhOBNhtAAQhsAAhOhNg");
        var mask_9_graphics_29 = new cjs.Graphics().p("Ak+E/QiEiEAAi7QAAi6CEiEQCEiEC6AAQC7AACECEQCECEAAC6QAAC7iECEQiECEi7AAQi6AAiEiEg");
        var mask_9_graphics_30 = new cjs.Graphics().p("AoiIjQjijjAAlAQAAk/DijjQDjjiE/AAQFAAADjDiQDiDjAAE/QAAFAjiDjQjjDilAAAQk/AAjjjig");
        var mask_9_graphics_31 = new cjs.Graphics().p("Ar/MAQk+k+AAnCQAAnBE+k+QE+k+HBAAQHCAAE+E+QE+E+AAHBQAAHCk+E+Qk+E+nCAAQnBAAk+k+g");
        var mask_9_graphics_32 = new cjs.Graphics().p("At+N+QlylyAAoMQAAoLFylzQFzlyILAAQIMAAFyFyQFzFzAAILQAAIMlzFyQlyFzoMAAQoLAAlzlzg");
        var mask_9_graphics_33 = new cjs.Graphics().p("AvNPOQmTmUAAo6QAAo5GTmUQGUmTI5AAQI6AAGUGTQGTGUAAI5QAAI6mTGUQmUGTo6AAQo5AAmUmTg");
        var mask_9_graphics_34 = new cjs.Graphics().p("AwDQEQmqmqAApaQAApZGqmqQGqmqJZAAQJaAAGqGqQGqGqAAJZQAAJamqGqQmqGqpaAAQpZAAmqmqg");
        var mask_9_graphics_35 = new cjs.Graphics().p("AwrQrQm6m6AApxQAApwG6m7QG7m6JwAAQJxAAG6G6QG7G7AAJwQAAJxm7G6Qm6G7pxAAQpwAAm7m7g");
        var mask_9_graphics_36 = new cjs.Graphics().p("AxIRIQnGnGAAqCQAAqBHGnHQHHnGKBAAQKCAAHGHGQHHHHAAKBQAAKCnHHGQnGHHqCAAQqBAAnHnHg");
        var mask_9_graphics_37 = new cjs.Graphics().p("AxeReQnPnPAAqPQAAqOHPnQQHQnPKOAAQKPAAHPHPQHQHQAAKOQAAKPnQHPQnPHQqPAAQqOAAnQnQg");
        var mask_9_graphics_38 = new cjs.Graphics().p("AxuRvQnWnWAAqZQAAqYHWnWQHWnWKYAAQKZAAHWHWQHWHWAAKYQAAKZnWHWQnWHWqZAAQqYAAnWnWg");
        var mask_9_graphics_39 = new cjs.Graphics().p("Ax6R7QnbnbAAqgQAAqfHbnbQHbnbKfAAQKgAAHbHbQHbHbAAKfQAAKgnbHbQnbHbqgAAQqfAAnbnbg");
        var mask_9_graphics_40 = new cjs.Graphics().p("AyDSDQneneAAqlQAAqkHenfQHfneKkAAQKlAAHeHeQHfHfAAKkQAAKlnfHeQneHfqlAAQqkAAnfnfg");
        var mask_9_graphics_41 = new cjs.Graphics().p("AyISJQnhnhAAqoQAAqnHhnhQHhnhKnAAQKoAAHhHhQHhHhAAKnQAAKonhHhQnhHhqoAAQqnAAnhnhg");
        var mask_9_graphics_42 = new cjs.Graphics().p("AyMSMQniniAAqqQAAqpHinjQHjniKpAAQKqAAHiHiQHjHjAAKpQAAKqnjHiQniHjqqAAQqpAAnjnjg");
        var mask_9_graphics_43 = new cjs.Graphics().p("AyNSNQniniAAqrQAAqqHinjQHjniKqAAQKrAAHiHiQHjHjAAKqQAAKrnjHiQniHjqrAAQqqAAnjnjg");

        this.timeline.addTween(cjs.Tween.get(mask_9).to({
            graphics: null,
            x: 0,
            y: 0
        }).wait(27).to({graphics: mask_9_graphics_27, x: -0.55, y: -0.5}).wait(1).to({
            graphics: mask_9_graphics_28,
            x: -0.9773,
            y: -0.8888
        }).wait(1).to({graphics: mask_9_graphics_29, x: -1.6693, y: -1.5184}).wait(1).to({
            graphics: mask_9_graphics_30,
            x: -2.8627,
            y: -2.6044
        }).wait(1).to({graphics: mask_9_graphics_31, x: -4.0189, y: -3.6563}).wait(1).to({
            graphics: mask_9_graphics_32,
            x: -4.6833,
            y: -4.2609
        }).wait(1).to({graphics: mask_9_graphics_33, x: -5.0976, y: -4.6379}).wait(1).to({
            graphics: mask_9_graphics_34,
            x: -5.3821,
            y: -4.8968
        }).wait(1).to({graphics: mask_9_graphics_35, x: -5.5881, y: -5.0842}).wait(1).to({
            graphics: mask_9_graphics_36,
            x: -5.7412,
            y: -5.2235
        }).wait(1).to({graphics: mask_9_graphics_37, x: -5.8561, y: -5.3281}).wait(1).to({
            graphics: mask_9_graphics_38,
            x: -5.942,
            y: -5.4063
        }).wait(1).to({graphics: mask_9_graphics_39, x: -6.0051, y: -5.4637}).wait(1).to({
            graphics: mask_9_graphics_40,
            x: -6.0496,
            y: -5.5042
        }).wait(1).to({graphics: mask_9_graphics_41, x: -6.0788, y: -5.5307}).wait(1).to({
            graphics: mask_9_graphics_42,
            x: -6.095,
            y: -5.5454
        }).wait(1).to({graphics: mask_9_graphics_43, x: 0, y: 0}).wait(71));

        // Line_vert
        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("AAA30MAAAAvp");
        this.shape_8.setTransform(0.65, -0.5);
        this.shape_8._off = true;

        var maskedShapeInstanceList = [this.shape_8];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_9;
        }

        this.timeline.addTween(cjs.Tween.get(this.shape_8).wait(27).to({_off: false}, 0).to({_off: true}, 69).wait(18));

        // Line_hor
        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("AX1AAMgvpAAA");
        this.shape_9.setTransform(0.15, 0);
        this.shape_9._off = true;

        var maskedShapeInstanceList = [this.shape_9];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_9;
        }

        this.timeline.addTween(cjs.Tween.get(this.shape_9).wait(27).to({_off: false}, 0).to({_off: true}, 69).wait(18));

        // mask_round (mask)
        var mask_10 = new cjs.Shape();
        mask_10._off = true;
        var mask_10_graphics_0 = new cjs.Graphics().p("Ak9Q+QnCnCAAp8QAAp8HCnBQHAnCJ9AAMAAAAv/Qp9AAnAnCg");
        var mask_10_graphics_1 = new cjs.Graphics().p("AqRNlQlhoQB8pwQB8pwIRlhQIQliJwB9MgJXAvEQpvh9lioRg");
        var mask_10_graphics_2 = new cjs.Graphics().p("AuuKJQjzpMDzpLQD0pMJMj0QJKjzJMD0MgSWAsVQpMj0j0pLg");
        var mask_10_graphics_3 = new cjs.Graphics().p("AyLGuQh8puFioRQFhoSJvh8QJwh8IRFiMgaqAn5QoRlih8pwg");
        var mask_10_graphics_4 = new cjs.Graphics().p("A0eDhQAAp7HCnCQHCnCJ7AAQJ8AAHCHCMgh7Ah7QnCnCAAp8g");
        var mask_10_graphics_5 = new cjs.Graphics().p("A1fArQB8pvISlhQIRliJvB8QJvB8FiISMgn5AapQlioRB8pwg");
        var mask_10_graphics_6 = new cjs.Graphics().p("A1NhvQDzpLJMjzQJLj0JMDzQJLD0D0JMMgsVASWQj0pMD0pLg");
        var mask_10_graphics_7 = new cjs.Graphics().p("AzsjpQFhoRJwh8QJwh8IRFhQIQFiB9JvMgvEAJXQh9pwFioQg");
        var mask_10_graphics_8 = new cjs.Graphics().p("A3/MAQAAp9HCnAQHCnCJ7AAQJ9AAHBHCQHCHAAAJ9g");
        var mask_10_graphics_9 = new cjs.Graphics().p("A3yFAQB9pvIRliQIQlhJwB8QJwB8FhIRQFiIQh9Jwg");
        var mask_10_graphics_10 = new cjs.Graphics().p("A3HhuQD0pMJLj0QJMjzJLDzQJMD0D0JMQDzJKj0JMg");
        var mask_10_graphics_11 = new cjs.Graphics().p("A1/n+QFioRJwh8QJuh8IRFiQISFhB8JvQB8JwliIRg");
        var mask_10_graphics_12 = new cjs.Graphics().p("A0etcQHCnCJ8AAQJ7AAHCHCQHCHCAAJ7QAAJ8nCHCg");
        var mask_10_graphics_13 = new cjs.Graphics().p("Ayrx5QIRliJwB8QJvB8FhISQFiIRh8JvQh8JvoSFig");
        var mask_10_graphics_14 = new cjs.Graphics().p("Awn1NQJMj0JLD0QJLDzDzJMQD0JLjzJMQj0JLpMD0g");
        var mask_10_graphics_15 = new cjs.Graphics().p("AuW3RQJwh9IQFiQIRFhB8JwQB8JwlhIRQliIQpvB9g");
        var mask_10_graphics_16 = new cjs.Graphics().p("Ar/3/QJ9AAHAHCQHCHCAAJ7QAAJ9nCHBQnAHCp9AAg");

        this.timeline.addTween(cjs.Tween.get(mask_10).to({
            graphics: mask_10_graphics_0,
            x: -76.8,
            y: 0
        }).wait(1).to({graphics: mask_10_graphics_1, x: -61.9565, y: -1.6155}).wait(1).to({
            graphics: mask_10_graphics_2,
            x: -47.6572,
            y: -6.0957
        }).wait(1).to({
            graphics: mask_10_graphics_3,
            x: -34.2793,
            y: -13.0978
        }).wait(1).to({
            graphics: mask_10_graphics_4,
            x: -22.5037,
            y: -22.5214
        }).wait(1).to({
            graphics: mask_10_graphics_5,
            x: -13.0864,
            y: -34.2869
        }).wait(1).to({graphics: mask_10_graphics_6, x: -6.0899, y: -47.6596}).wait(1).to({
            graphics: mask_10_graphics_7,
            x: -1.6138,
            y: -61.9568
        }).wait(1).to({graphics: mask_10_graphics_8, x: 0, y: -76.8}).wait(1).to({
            graphics: mask_10_graphics_9,
            x: 1.6155,
            y: -61.9565
        }).wait(1).to({
            graphics: mask_10_graphics_10,
            x: 6.0957,
            y: -47.6572
        }).wait(1).to({
            graphics: mask_10_graphics_11,
            x: 13.0978,
            y: -34.2793
        }).wait(1).to({
            graphics: mask_10_graphics_12,
            x: 22.5214,
            y: -22.5037
        }).wait(1).to({
            graphics: mask_10_graphics_13,
            x: 34.2869,
            y: -13.0864
        }).wait(1).to({
            graphics: mask_10_graphics_14,
            x: 47.6596,
            y: -6.0899
        }).wait(1).to({
            graphics: mask_10_graphics_15,
            x: 61.9568,
            y: -1.6138
        }).wait(1).to({graphics: mask_10_graphics_16, x: 76.8, y: 0}).wait(98));

        // ro
        this.shape_10 = new cjs.Shape();
        this.shape_10.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("Ar33vQE0AAEaB3QEOB0DSDSQDSDTBzEQQB3EbAAE0QAAE2h3EaQhzERjSDSQjSDSkOB0QkaB3k0AAAr3s9QCoAACZBBQCUA/ByBzQByBzA+CVQBBCaAACoQAACphBCaQg+CVhyBzQhyBziUA/QiZBBioAA");
        this.shape_10.setTransform(76.15, -0.5);

        var maskedShapeInstanceList = [this.shape_10];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_10;
        }

        this.timeline.addTween(cjs.Tween.get(this.shape_10).to({_off: true}, 96).wait(18));

        // ma_2
        this.shape_11 = new cjs.Shape();
        this.shape_11.graphics.f("#1D1D22").s().p("EgUfAoYMAAAhQvMAo/AAAMAAABQvg");
        this.shape_11.setTransform(131.2, -2.4);

        this.shape_12 = new cjs.Shape();
        this.shape_12.graphics.f("#1D1D22").s().p("EgC6AoYMAAAhQvIF1AAMAAABQvg");
        this.shape_12.setTransform(243.7, -2.4);

        this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.shape_11}]}).to({state: [{t: this.shape_12}]}, 95).to({state: []}, 1).wait(18));

        // mask_round_2 (mask)
        var mask_11 = new cjs.Shape();
        mask_11._off = true;
        var mask_11_graphics_0 = new cjs.Graphics().p("Ar/3/QJ9AAHAHCQHCHCAAJ7QAAJ9nCHBQnAHCp9AAg");
        var mask_11_graphics_16 = new cjs.Graphics().p("Ar/3/QJ9AAHAHCQHCHCAAJ7QAAJ9nCHBQnAHCp9AAg");
        var mask_11_graphics_17 = new cjs.Graphics().p("AuWXSMAJXgvEQJvB9FiIRQFhIQh8JwQh8JwoRFhQmGEGm8AAQicAAiighg");
        var mask_11_graphics_18 = new cjs.Graphics().p("AwnVOMASWgsVQJMD0D0JLQDzJMjzJLQj0JMpMD0QkkB5kmAAQkmAAkmh6g");
        var mask_11_graphics_19 = new cjs.Graphics().p("AyrR6MAaqgn5QIRFiB8JwQB8JuliIRQlhISpvB8QiiAgibAAQm8AAmIkGg");
        var mask_11_graphics_20 = new cjs.Graphics().p("A0eNdMAh7gh7QHCHCAAJ8QAAJ7nCHCQnCHCp7AAQp8AAnCnCg");
        var mask_11_graphics_21 = new cjs.Graphics().p("AmuSMQpvh8lioSMAn5gapQFiIRh8JwQh8JvoSFhQmIEGm7AAQibAAiiggg");
        var mask_11_graphics_22 = new cjs.Graphics().p("AqIOvQpLj0j0pMMAsVgSWQD0JMj0JLQjzJLpMDzQkmB6kmAAQklAAkmh5g");
        var mask_11_graphics_23 = new cjs.Graphics().p("AtlKSQoQlih9pvMAvEgJXQB9JwliIQQlhIRpwB8QijAgibAAQm7AAmIkFg");
        var mask_11_graphics_24 = new cjs.Graphics().p("Aw9E+QnCnAAAp9MAv/AAAQAAJ9nCHAQnCHCp8AAQp8AAnBnCg");
        var mask_11_graphics_25 = new cjs.Graphics().p("AlAODQpsiOlToaQlSoaCNptMAuyAKsQiNJsobFSQl7DvmlAAQivAAi3gqg");
        var mask_11_graphics_26 = new cjs.Graphics().p("ApLOzQo9kUjTpZQjSpXEVo+MArOAU0QkUI+pYDSQkEBbj+AAQlNAAlGidg");
        var mask_11_graphics_27 = new cjs.Graphics().p("AsUOPQnymNhIp3QhGp4GMnyMAlhAd7QmNHxp4BIQhgALhdAAQoFAAmmlRg");
        var mask_11_graphics_28 = new cjs.Graphics().p("AB2VOQp3hHmNnyQmNnxBHp3QBIp4HxmNMAd7AlhQmmFQoHAAQhdAAhggLg");
        var mask_11_graphics_29 = new cjs.Graphics().p("AhGVbQpYjRkUo+QkUo9DSpYQDSpYI+kUMAU0ArOQlGCdlOAAQj/AAkDhbg");
        var mask_11_graphics_30 = new cjs.Graphics().p("AjaUAQoalTiOpsQiNpsFSoaQFSobJsiNMAKsAuyQi3ApiwAAQmlAAl7jug");
        var mask_11_graphics_31 = new cjs.Graphics().p("Ak9Q+QnCnCAAp8QAAp8HCnBQHAnCJ9AAMAAAAv/Qp9AAnAnCg");

        this.timeline.addTween(cjs.Tween.get(mask_11).to({
            graphics: mask_11_graphics_0,
            x: 76.8,
            y: 0
        }).wait(16).to({graphics: mask_11_graphics_16, x: 76.8, y: 0}).wait(1).to({
            graphics: mask_11_graphics_17,
            x: 61.9565,
            y: 1.6155
        }).wait(1).to({graphics: mask_11_graphics_18, x: 47.6572, y: 6.0957}).wait(1).to({
            graphics: mask_11_graphics_19,
            x: 34.2793,
            y: 13.0978
        }).wait(1).to({
            graphics: mask_11_graphics_20,
            x: 22.5037,
            y: 22.5214
        }).wait(1).to({
            graphics: mask_11_graphics_21,
            x: 13.0864,
            y: 34.2869
        }).wait(1).to({graphics: mask_11_graphics_22, x: 6.0899, y: 47.6596}).wait(1).to({
            graphics: mask_11_graphics_23,
            x: 1.6138,
            y: 61.9568
        }).wait(1).to({graphics: mask_11_graphics_24, x: 0, y: 76.8}).wait(1).to({
            graphics: mask_11_graphics_25,
            x: -2.0908,
            y: 59.8752
        }).wait(1).to({
            graphics: mask_11_graphics_26,
            x: -7.8481,
            y: 43.7167
        }).wait(1).to({
            graphics: mask_11_graphics_27,
            x: -16.8362,
            y: 28.9857
        }).wait(1).to({
            graphics: mask_11_graphics_28,
            x: -28.9969,
            y: 16.8222
        }).wait(1).to({
            graphics: mask_11_graphics_29,
            x: -43.7202,
            y: 7.8408
        }).wait(1).to({
            graphics: mask_11_graphics_30,
            x: -59.8757,
            y: 2.0887
        }).wait(1).to({graphics: mask_11_graphics_31, x: -76.8, y: 0}).wait(83));

        // roror
        this.shape_13 = new cjs.Shape();
        this.shape_13.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("AM5AAQAACphBCaQg+CVhyBzQhzBziUA/QiZBBioAAQinAAiZhBQiUg/hzhzQhyhzg+iVQhBiaAAipQAAioBBiaQA+iVByhyQBzhzCUg/QCZhCCnAAQCoAACZBCQCUA/BzBzQByByA+CVQBBCaAACog");
        this.shape_13.setTransform(0.15, -0.5);

        this.shape_14 = new cjs.Shape();
        this.shape_14.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("AXrAAQAAE1h3EaQhzERjSDTQjSDSkPBzQkZB4k1AAQkzAAkZh4QkQhzjSjSQjSjThzkRQh3kaAAk1QAAk0B3kaQBzkRDSjTQDSjSEQh0QEZh3EzAAQE1AAEZB3QEPB0DSDSQDSDTBzERQB3EaAAE0g");
        this.shape_14.setTransform(0.15, -0.5);

        var maskedShapeInstanceList = [this.shape_13, this.shape_14];

        for (var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
            maskedShapeInstanceList[shapedInstanceItr].mask = mask_11;
        }

        this.timeline.addTween(cjs.Tween.get({}).to({state: [{t: this.shape_14}, {t: this.shape_13}]}).wait(114));

        // round
        this.shape_15 = new cjs.Shape();
        this.shape_15.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("AX1AAMgvpAAA");
        this.shape_15.setTransform(0.15, 0);

        this.shape_16 = new cjs.Shape();
        this.shape_16.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("Ae0xxMg9nAjj");
        this.shape_16.setTransform(0.425, -0.05);

        this.shape_17 = new cjs.Shape();
        this.shape_17.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("Ae0RyMg9ngjj");
        this.shape_17.setTransform(-0.075, -0.05);

        this.shape_18 = new cjs.Shape();
        this.shape_18.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("AAA30MAAAAvp");
        this.shape_18.setTransform(0.65, -0.5);

        this.shape_19 = new cjs.Shape();
        this.shape_19.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("AM5AAQAACphBCaQg+CVhyBzQhzBziUA/QiZBBioAAQinAAiZhBQiUg/hzhzQhyhzg+iVQhBiaAAipQAAioBBiaQA+iVByhyQBzhzCUg/QCZhCCnAAQCoAACZBCQCUA/BzBzQByByA+CVQBBCaAACog");
        this.shape_19.setTransform(0.15, -0.5);

        this.shape_20 = new cjs.Shape();
        this.shape_20.graphics.f().s("#EB3223").ss(1, 0, 0, 4).p("AXrAAQAAE1h3EaQhzERjSDTQjSDSkPBzQkZB4k1AAQkzAAkZh4QkQhzjSjSQjSjThzkRQh3kaAAk1QAAk0B3kaQBzkRDSjTQDSjSEQh0QEZh3EzAAQE1AAEZB3QEPB0DSDSQDSDTBzERQB3EaAAE0g");
        this.shape_20.setTransform(0.15, -0.5);

        this.timeline.addTween(cjs.Tween.get({}).to({state: []}).to({state: [{t: this.shape_20}, {t: this.shape_19}, {t: this.shape_18}, {t: this.shape_17}, {t: this.shape_16}, {t: this.shape_15}]}, 96).wait(18));

        // bg
        this.shape_21 = new cjs.Shape();
        this.shape_21.graphics.f("#1D1D22").s().p("EhwfA+gMAAAh8/MDg/AAAMAAAB8/g");
        this.shape_21.setTransform(0, 0, 0.3125, 1);

        this.timeline.addTween(cjs.Tween.get(this.shape_21).wait(114));

        this._renderFirstFrame();

    }).prototype = p = new cjs.MovieClip();
    p.nominalBounds = new cjs.Rectangle(-718.4, -805.6, 1440, 1611.3000000000002);


// stage content:
    (lib.fantastic_mob_1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) {
            loop = true;
        }
        if (reversed == null) {
            reversed = false;
        }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Layer_2
        this.instance = new lib.main();
        this.instance.setTransform(215.05, 400, 0.6415, 0.6415, 0, 0, 0, 0.1, 0);

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(114));

        this._renderFirstFrame();

    }).prototype = p = new lib.AnMovieClip();
    p.nominalBounds = new cjs.Rectangle(201.9, 543.4, 259.5, 113.20000000000005);
// library properties:
    lib.properties = {
        id: 'B8E4F8D7ACE64AC297F6887B5CE714D7',
        width: 450,
        height: 800,
        fps: 25,
        color: "#1D1C22",
        opacity: 1.00,
        manifest: [],
        preloads: []
    };


// bootstrap callback support:

    (lib.Stage = function (canvas) {
        createjs.Stage.call(this, canvas);
    }).prototype = p = new createjs.Stage();

    p.setAutoPlay = function (autoPlay) {
        this.tickEnabled = autoPlay;
    }
    p.play = function () {
        this.tickEnabled = true;
        this.getChildAt(0).gotoAndPlay(this.getTimelinePosition())
    }
    p.stop = function (ms) {
        if (ms) this.seek(ms);
        this.tickEnabled = false;
    }
    p.seek = function (ms) {
        this.tickEnabled = true;
        this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000);
    }
    p.getDuration = function () {
        return this.getChildAt(0).totalFrames / lib.properties.fps * 1000;
    }

    p.getTimelinePosition = function () {
        return this.getChildAt(0).currentFrame / lib.properties.fps * 1000;
    }

    an.bootcompsLoaded = an.bootcompsLoaded || [];
    if (!an.bootstrapListeners) {
        an.bootstrapListeners = [];
    }

    an.bootstrapCallback = function (fnCallback) {
        an.bootstrapListeners.push(fnCallback);
        if (an.bootcompsLoaded.length > 0) {
            for (var i = 0; i < an.bootcompsLoaded.length; ++i) {
                fnCallback(an.bootcompsLoaded[i]);
            }
        }
    };

    an.compositions = an.compositions || {};
    an.compositions['B8E4F8D7ACE64AC297F6887B5CE714D7'] = {
        getStage: function () {
            return exportRoot.stage;
        },
        getLibrary: function () {
            return lib;
        },
        getSpriteSheet: function () {
            return ss;
        },
        getImages: function () {
            return img;
        }
    };

    an.compositionLoaded = function (id) {
        an.bootcompsLoaded.push(id);
        for (var j = 0; j < an.bootstrapListeners.length; j++) {
            an.bootstrapListeners[j](id);
        }
    }

    an.getComposition = function (id) {
        return an.compositions[id];
    }


    an.makeResponsive = function (isResp, respDim, isScale, scaleType, domContainers) {
        var lastW, lastH, lastS = 1;
        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();

        function resizeCanvas() {
            var w = lib.properties.width, h = lib.properties.height;
            var iw = window.innerWidth, ih = window.innerHeight;
            var pRatio = window.devicePixelRatio || 1, xRatio = iw / w, yRatio = ih / h, sRatio = 1;
            if (isResp) {
                if ((respDim == 'width' && lastW == iw) || (respDim == 'height' && lastH == ih)) {
                    sRatio = lastS;
                } else if (!isScale) {
                    if (iw < w || ih < h)
                        sRatio = Math.min(xRatio, yRatio);
                } else if (scaleType == 1) {
                    sRatio = Math.min(xRatio, yRatio);
                } else if (scaleType == 2) {
                    sRatio = Math.max(xRatio, yRatio);
                }
            }
            domContainers[0].width = w * pRatio * sRatio;
            domContainers[0].height = h * pRatio * sRatio;
            domContainers.forEach(function (container) {
                container.style.width = w * sRatio + 'px';
                container.style.height = h * sRatio + 'px';
            });
            an.stage.scaleX = pRatio * sRatio;
            an.stage.scaleY = pRatio * sRatio;
            lastW = iw;
            lastH = ih;
            lastS = sRatio;
            an.stage.tickOnUpdate = false;
            an.stage.update();
            an.stage.tickOnUpdate = true;
        }
    }
    an.handleSoundStreamOnTick = function (event) {
        if (!event.paused) {
            var stageChild = an.stage.getChildAt(0);
            if (!stageChild.paused) {
                stageChild.syncStreamSounds();
            }
        }
    }

	return an;
}
