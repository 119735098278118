export function navigationHorizontal(navLinks, masterTl, viewPort) {

    let tween = masterTl;

    navLinks.forEach((anchor, index) => {

        anchor.addEventListener("click", function (e) {
            e.preventDefault();

            const name = e.target.getAttribute("href");
            let totalScroll = tween.scrollTrigger.end - tween.scrollTrigger.start

            if (!name) {
                gsap.to(window, {
                    scrollTo: {
                        y: 0,
                        autoKill: false
                    },
                    duration: 1
                });

            }

            if (name === '#panel-about') {
                gsap.to(window, {
                    scrollTo: {y: totalScroll * 0.149933},
                    duration: 1
                });
            }

            if (name === '#panel-clients') {
                gsap.to(window, {
                    scrollTo: {y: totalScroll * 0.322},
                    duration: 1
                });
            }

            if (name === '#panel-process') {
                gsap.to(window, {
                    scrollTo: {y: totalScroll * 0.476666},
                    duration: 1
                });
            }

            if (name === '#panel-contacts') {
                gsap.to(window, {
                    scrollTo: {y: "max"},
                    duration: 1
                });
            }
        });
    });
}