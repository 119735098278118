import {getCircleSizeByViewPort} from "../utils/getCircleSizeByViewPort";

const delay = '>-3';
const boxDuration = 3;
const textDuration = 3;
const steps = ['research', 'strategy', 'tactics', 'implementation']


export function moveProcessElement(viewPort, delta, box, step) {

    const dotPosition = delta[step - 1]
    const {circle, deltaY, textScale} = getCircleSizeByViewPort(viewPort)

    const distance = dotPosition + (circle * 0.5)

    const tl = gsap.timeline()

    const stepName = steps[step - 1]
    const x = distance

    tl.to(box, {x, duration: boxDuration})
    tl.add(toggleTexts(stepName, textScale, deltaY), delay)
    tl.add(toggleInfo(stepName), delay)

    return tl
}


export function circleToPage(viewPort, box) {
    const tl = gsap.timeline()
    tl.to(box, {scale: 6, xPercent: 300, duration: boxDuration})
        .to('#dot-implementation .dot-text .top', {scale: 1, x: 0, y: 0, duration: textDuration}, 0)
        .to('#dot-implementation .dot-text .bottom', {scale: 1, x: 0, y: 0, duration: textDuration}, 0)

    return tl
}


export function getDeltaDot() {
    return gsap.utils.toArray(".dot").map(i => {

        const width = i.offsetWidth
        return i.offsetLeft + (width / 2)
    });
}

export function getText(type) {
    const top = document.querySelector(`#dot-${type} .dot-text .top`);
    const topW = top && top.offsetWidth;

    const bottom = document.querySelector(`#dot-${type} .dot-text .bottom`)
    return {top, topW, bottom}
}

function toggleTexts(active, textScale, deltaY) {
    const textTl = gsap.timeline()
    const lineData = [
        {
            name: 'research',
            text: getText('research'),
        },
        {
            name: 'strategy',
            text: getText('strategy')
        },
        {
            name: 'tactics',
            text: getText('tactics')
        },
        {
            name: 'implementation',
            text: getText('implementation')
        },
    ]

    const activeText = lineData.find(item => item.name === active)
    const restText = lineData.filter(item => item.name !== active)

    const {top, bottom, topW} = activeText.text

    const activeAnimation = gsap.timeline()
        .to(top, {scale: textScale, y: deltaY, x: -50, duration: textDuration}, 0)
        .to(bottom, {scale: textScale, y: 0, x: -(topW + 50), duration: textDuration}, 0)

    restText.forEach(data => {
        const animation = gsap.timeline()
            .to(data.text.top, {scale: 1, x: 0, y: 0, duration: textDuration}, 0)
            .to(data.text.bottom, {scale: 1, x: 0, y: 0, duration: textDuration}, 0)

        textTl.add(animation, 0);
    })

    textTl.add(activeAnimation, 0);

    return textTl
}

function toggleInfo(active) {
    const textTl = gsap.timeline()
    const restNames = steps.filter(i => i !== active).map(i => `.text-${i}`)

    const target = document.querySelector(`.text-${active}`)

    textTl
        .to(restNames, {visibility: 'hidden', duration: 1}, 0)
        .to(target, {visibility: 'visible', duration: 1}, 0)

    return textTl;
}