export function fanaticAnimationHome(cjs, an) {

	var p;
	var lib={};
	var ss={};
	var img={};
	lib.ssMetadata = [];


	(lib.AnMovieClip = function(){
		this.actionFrames = [];
		this.gotoAndPlay = function(positionOrLabel){
			cjs.MovieClip.prototype.gotoAndPlay.call(this,positionOrLabel);
		}
		this.play = function(){
			cjs.MovieClip.prototype.play.call(this);
		}
		this.gotoAndStop = function(positionOrLabel){
			cjs.MovieClip.prototype.gotoAndStop.call(this,positionOrLabel);
		}
		this.stop = function(){
			cjs.MovieClip.prototype.stop.call(this);
		}
	}).prototype = p = new cjs.MovieClip();

	function mc_symbol_clone() {
		var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
		clone.gotoAndStop(this.currentFrame);
		clone.paused = this.paused;
		clone.framerate = this.framerate;
		return clone;
	}

	function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
		var prototype = cjs.extend(symbol, cjs.MovieClip);
		prototype.clone = mc_symbol_clone;
		prototype.nominalBounds = nominalBounds;
		prototype.frameBounds = frameBounds;
		return prototype;
	}

	(lib.T_1 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#FFFFFF").s().p("AhuGpIAAp9IjYAAIAAjUIKNAAIAADUIjYAAIAAJ9g");
		this.shape.setTransform(0.025,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.T_1, new cjs.Rectangle(-32.7,-42.4,65.5,84.9), null);

	(lib.N_2 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#FFFFFF").s().p("ADdGpIl0nAIAAHAIjeAAIAAtRICYAAIF1HCIAAnCIDeAAIAANRg");
		this.shape.setTransform(0.025,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.N_2, new cjs.Rectangle(-37.4,-42.4,74.9,84.9), null);

	(lib.N_1 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#FFFFFF").s().p("ADdGpIl0nAIAAHAIjeAAIAAtRICYAAIF1HCIAAnCIDeAAIAANRg");
		this.shape.setTransform(0.025,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.N_1, new cjs.Rectangle(-37.4,-42.4,74.9,84.9), null);

	(lib.line_diag_2 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f().s("#EB3223").ss(1,0,0,4).p("Ae0RyMg9ngjj");
		this.shape.setTransform(0.025,0);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.line_diag_2, new cjs.Rectangle(-198.1,-114.8,396.29999999999995,229.7), null);

	(lib.line_diag_1 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f().s("#EB3223").ss(1,0,0,4).p("Ae0xxMg9nAjj");
		this.shape.setTransform(0.025,0);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.line_diag_1, new cjs.Rectangle(-198.1,-114.8,396.29999999999995,229.7), null);

	(lib.I_1 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#FFFFFF").s().p("AhuGpIAAtRIDdAAIAANRg");
		this.shape.setTransform(0,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.I_1, new cjs.Rectangle(-11.1,-42.4,22.299999999999997,84.9), null);

	(lib.ClipGroup = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_2 (mask)
		var mask = new cjs.Shape();
		mask._off = true;
		mask.graphics.p("EhwfA+gMAAAh8/MDg/AAAMAAAB8/g");
		mask.setTransform(720,400);

		// Layer_3
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#000000").s().p("AhkGeQhUggg9g6Qg7g5gjhUQgjhSAAhlQAAhkAkhUQAjhSA9g7QA+g7BQgfQBTggBaAAQBGAAA9AOQA6APAoASQAgANAbARIhwDGIgWgOQgcgNghgKQglgNgtAAQgwAAgpASQgpARgeAfQgeAegSAoQgSApAAAtQAAAtATAqQATAqAeAdQAgAeAqARQAoARAyAAQAxAAAmgMQAngNAYgPQALgGANgIIBvDFQgbASghAPQgoASg8AQQg4AQhMAAQhjAAhUgig");
		this.shape.setTransform(932.275,398.75);

		this.shape_1 = new cjs.Shape();
		this.shape_1.graphics.f("#000000").s().p("AhuGpIAAtRIDdAAIAANRg");
		this.shape_1.setTransform(871.25,398.675);

		this.shape_2 = new cjs.Shape();
		this.shape_2.graphics.f("#000000").s().p("AhuGpIAAp9IjYAAIAAjUIKNAAIAADUIjYAAIAAJ9g");
		this.shape_2.setTransform(814.075,398.675);

		this.shape_3 = new cjs.Shape();
		this.shape_3.graphics.f("#000000").s().p("ADdGpIl0nAIAAHAIjeAAIAAtRICYAAIF1HCIAAnCIDeAAIAANRg");
		this.shape_3.setTransform(653.775,398.675);

		this.shape_4 = new cjs.Shape();
		this.shape_4.graphics.f("#000000").s().p("AkYGpIAAtRIIxAAIAADUIlTAAIAACUIEHAAIAADEIkHAAIAAElg");
		this.shape_4.setTransform(499.125,398.675);

		this.shape_5 = new cjs.Shape();
		this.shape_5.graphics.f("#000000").s().p("ADbGpIg1iOIlLAAIg2COIjbAAIFAtRIDsAAIFBNRgABeBdIhej9IhfD9IC9AAg");
		this.shape_5.setTransform(566.3,398.775);

		this.shape_6 = new cjs.Shape();
		this.shape_6.graphics.f("#000000").s().p("ADcGpIg1iOIlMAAIg2COIjbAAIFAtRIDsAAIFBNRgABfBdIhfj9IheD9IC9AAg");
		this.shape_6.setTransform(742.05,398.775);

		this.shape_7 = new cjs.Shape();
		this.shape_7.graphics.f().s("#EB3223").ss(1,0,0,4).p("Ae0RyMg9ngjj");
		this.shape_7.setTransform(719.925,399.95);

		this.shape_8 = new cjs.Shape();
		this.shape_8.graphics.f().s("#EB3223").ss(1,0,0,4).p("Ae0xxMg9nAjj");
		this.shape_8.setTransform(720.425,399.95);

		this.shape_9 = new cjs.Shape();
		this.shape_9.graphics.f().s("#EB3223").ss(1,0,0,4).p("AX1AAMgvpAAA");
		this.shape_9.setTransform(720.15,400);

		this.shape_10 = new cjs.Shape();
		this.shape_10.graphics.f().s("#EB3223").ss(1,0,0,4).p("AAA30MAAAAvp");
		this.shape_10.setTransform(720.65,399.5);

		this.shape_11 = new cjs.Shape();
		this.shape_11.graphics.f().s("#EB3223").ss(1,0,0,4).p("AM5AAQAACphBCaQg+CVhyBzQhzBziUA/QiZBBioAAQinAAiZhBQiUg/hzhzQhyhzg+iVQhBiaAAipQAAioBBiaQA+iVByhyQBzhzCUg/QCZhCCnAAQCoAACZBCQCUA/BzBzQByByA+CVQBBCaAACog");
		this.shape_11.setTransform(720.15,399.5);

		this.shape_12 = new cjs.Shape();
		this.shape_12.graphics.f().s("#EB3223").ss(1,0,0,4).p("AXrAAQAAE1h3EaQhzERjSDTQjSDSkPBzQkZB4k1AAQkzAAkZh4QkQhzjSjSQjSjThzkRQh3kaAAk1QAAk0B3kaQBzkRDSjTQDSjSEQh0QEZh3EzAAQE1AAEZB3QEPB0DSDSQDSDTBzERQB3EaAAE0g");
		this.shape_12.setTransform(720.15,399.5);

		var maskedShapeInstanceList = [this.shape,this.shape_1,this.shape_2,this.shape_3,this.shape_4,this.shape_5,this.shape_6,this.shape_7,this.shape_8,this.shape_9,this.shape_10,this.shape_11,this.shape_12];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask;
		}

		this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_12},{t:this.shape_11},{t:this.shape_10},{t:this.shape_9},{t:this.shape_8},{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.ClipGroup, new cjs.Rectangle(471,246,498.79999999999995,307), null);

	(lib.F_2 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#FFFFFF").s().p("AkYGpIAAtRIIxAAIAADUIlTAAIAACUIEHAAIAADEIkHAAIAAElg");
		this.shape.setTransform(0.025,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.F_2, new cjs.Rectangle(-28.1,-42.4,56.3,84.9), null);

	(lib.F_1 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#FFFFFF").s().p("AkYGpIAAtRIIxAAIAADUIlTAAIAACUIEHAAIAADEIkHAAIAAElg");
		this.shape.setTransform(0.025,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.F_1, new cjs.Rectangle(-28.1,-42.4,56.3,84.9), null);

	(lib.C_1 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#FFFFFF").s().p("AhkGeQhUggg9g6Qg7g5gjhUQgjhSAAhlQAAhkAkhUQAjhSA9g7QA+g7BQgfQBTggBaAAQBGAAA9AOQA6APAoASQAgANAbARIhwDGIgWgOQgcgNghgKQglgNgtAAQgwAAgpASQgpARgeAfQgeAegSAoQgSApAAAtQAAAtATAqQATAqAeAdQAgAeAqARQAoARAyAAQAxAAAmgMQAngNAYgPQALgGANgIIBvDFQgbASghAPQgoASg8AQQg4AQhMAAQhjAAhUgig");
		this.shape.setTransform(0.025,0);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.C_1, new cjs.Rectangle(-37.4,-44.7,74.9,89.5), null);

	(lib.A_2 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#FFFFFF").s().p("ADbGpIg1iOIlLAAIg2COIjbAAIFAtRIDsAAIFBNRgABeBdIhej9IhfD9IC9AAg");
		this.shape.setTransform(0,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.A_2, new cjs.Rectangle(-43.9,-42.4,87.8,84.9), null);

	(lib.A_1_2 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#FFFFFF").s().p("ADcGpIg1iOIlMAAIg2COIjbAAIFAtRIDsAAIFBNRgABfBdIhfj9IheD9IC9AAg");
		this.shape.setTransform(0,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.A_1_2, new cjs.Rectangle(-43.9,-42.4,87.8,84.9), null);

	(lib.A_1 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.shape = new cjs.Shape();
		this.shape.graphics.f("#FFFFFF").s().p("ADbGpIg1iOIlLAAIg2COIjbAAIFAtRIDsAAIFBNRgABeBdIhej9IhfD9IC9AAg");
		this.shape.setTransform(0,0.025);

		this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

		this._renderFirstFrame();

	}).prototype = getMCSymbolPrototype(lib.A_1, new cjs.Rectangle(-43.9,-42.4,87.8,84.9), null);

	(lib.Tween1 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		// Layer_1
		this.instance = new lib.A_1_2();
		this.instance.setTransform(0,65.85);

		this.instance_1 = new lib.T_1();
		this.instance_1.setTransform(-1.15,-65.9);

		this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_1},{t:this.instance}]}).wait(1));

		this._renderFirstFrame();

	}).prototype = p = new cjs.MovieClip();
	p.nominalBounds = new cjs.Rectangle(-43.9,-108.3,87.8,216.7);

	(lib.fantastic_1 = function(mode,startPosition,loop,reversed) {
		if (loop == null) { loop = true; }
		if (reversed == null) { reversed = false; }
		var props = new Object();
		props.mode = mode;
		props.startPosition = startPosition;
		props.labels = {};
		props.loop = loop;
		props.reversed = reversed;
		cjs.MovieClip.apply(this,[props]);

		this.actionFrames = [113];
		// timeline functions:
		this.frame_113 = function() {
			this.stop()
		}

		// actions tween:
		this.timeline.addTween(cjs.Tween.get(this).wait(113).call(this.frame_113).wait(1));

		// Layer_35 (mask)
		var mask = new cjs.Shape();
		mask._off = true;
		var mask_graphics_96 = new cjs.Graphics().p("AipCqQhGhGAAhkQAAhiBGhHQBHhGBiAAQBjAABGBGQBHBHAABiQAABkhHBGQhGBGhjAAQhiAAhHhGg");
		var mask_graphics_97 = new cjs.Graphics().p("EAnLAiJQkIkDAAlxQAAlwEIkFQEJkDF4AAQF3AAEIEDQEJEFAAFwQAAFxkJEDQkIEFl3AAQl4AAkJkFg");
		var mask_graphics_98 = new cjs.Graphics().p("EAR+AltQpQpCAAs3QAAs2JQpGQJTpDNJAAQNJAAJPJDQJUJGAAM2QAAM3pUJCQpPJHtJAAQtJAApTpHg");
		var mask_graphics_99 = new cjs.Graphics().p("EgSXAr1QyBxmAA5BQAA5ASBxvQSJxmZmAAQZmAASBRmQSJRvAAZAQAAZByJRmQyBRu5mAAQ5mAAyJxug");
		var mask_graphics_100 = new cjs.Graphics().p("EgwGA7kUgZMgYmAAAgi+UAAAgi9AZMgYxUAZYgYnAjyAAAUAj0AAAAZMAYnUAZYAYxAAAAi9UAAAAi+gZYAYmUgZMAYygj0AAAUgjyAAAgZYgYyg");
		var mask_graphics_101 = new cjs.Graphics().p("EhAlBE+UgdLgcfAAAgofUAAAgoeAdLgcsUAdYgceApdAAAUApdAAAAdLAceUAdYAcsAAAAoeUAAAAofgdYAcfUgdLAcrgpdAAAUgpdAAAgdYgcrg");
		var mask_graphics_102 = new cjs.Graphics().p("EhK8BK4Ugfrge7AAAgr9UAAAgr8AfrgfJUAf5ge7AtAAAAUAtBAAAAfrAe7UAf6AfJAAAAr8UAAAAr9gf6Ae7UgfrAfJgtBAAAUgtAAAAgf5gfJg");
		var mask_graphics_103 = new cjs.Graphics().p("EhRFBO8UghZggnAAAguVUAAAguUAhZgg2UAhoggmAvdAAAUAvdAAAAhZAgmUAhpAg2AAAAuUUAAAAuVghpAgnUghZAg1gvdAAAUgvdAAAghogg1g");
		var mask_graphics_104 = new cjs.Graphics().p("EhUFBR3Ugiogh0AAAgwDUAAAgwCAiogiDUAi4gh0AxNAAAUAxOAAAAioAh0UAi4AiDAAAAwCUAAAAwDgi4Ah0UgioAiDgxOAAAUgxNAAAgi4giDg");
		var mask_graphics_105 = new cjs.Graphics().p("EhWSBUAUgjigisAAAgxUUAAAgxTAjigi8UAjygisAygAAAUAyhAAAAjiAisUAjyAi8AAAAxTUAAAAxUgjyAisUgjiAi8gyhAAAUgygAAAgjygi8g");
		var mask_graphics_106 = new cjs.Graphics().p("EhX6BVlUgkNgjWAAAgyPUAAAgyOAkNgjmUAkdgjWAzdAAAUAzeAAAAkNAjWUAkdAjmAAAAyOUAAAAyPgkdAjWUgkNAjmgzeAAAUgzdAAAgkdgjmg");
		var mask_graphics_107 = new cjs.Graphics().p("EhZFBWuUgksgj0AAAgy6UAAAgy5AksgkFUAk8gj0A0JAAAUA0KAAAAksAj0UAk8AkFAAAAy5UAAAAy6gk8Aj0UgksAkFg0KAAAUg0JAAAgk8gkFg");
		var mask_graphics_108 = new cjs.Graphics().p("EhZ6BXiUglCgkKAAAgzYUAAAgzXAlCgkaUAlTgkJA0nAAAUA0oAAAAlCAkJUAlTAkaAAAAzXUAAAAzYglTAkKUglCAkZg0oAAAUg0nAAAglTgkZg");
		var mask_graphics_109 = new cjs.Graphics().p("EhacBYDUglQgkXAAAgzsUAAAgzrAlQgknUAlhgkYA07AAAUA08AAAAlQAkYUAlhAknAAAAzrUAAAAzsglhAkXUglQAkog08AAAUg07AAAglhgkog");
		var mask_graphics_110 = new cjs.Graphics().p("EhavBYVUglYgkeAAAgz3UAAAgz2AlYgkvUAlpgkfA1GAAAUA1HAAAAlYAkfUAlpAkvAAAAz2UAAAAz3glpAkeUglYAkwg1HAAAUg1GAAAglpgkwg");
		var mask_graphics_111 = new cjs.Graphics().p("Eha1BYbUglagkhAAAgz6UAAAgz5AlagkyUAlrgkhA1KAAAUA1LAAAAlaAkhUAlrAkyAAAAz5UAAAAz6glrAkhUglaAkyg1LAAAUg1KAAAglrgkyg");

		this.timeline.addTween(cjs.Tween.get(mask).to({graphics:null,x:0,y:0}).wait(96).to({graphics:mask_graphics_96,x:720,y:400}).wait(1).to({graphics:mask_graphics_97,x:405.2399,y:244.5768}).wait(1).to({graphics:mask_graphics_98,x:461.387,y:299.6038}).wait(1).to({graphics:mask_graphics_99,x:557.6325,y:393.9292}).wait(1).to({graphics:mask_graphics_100,x:636.3646,y:402.4197}).wait(1).to({graphics:mask_graphics_101,x:680.0202,y:402.8192}).wait(1).to({graphics:mask_graphics_102,x:707.4619,y:403.0704}).wait(1).to({graphics:mask_graphics_103,x:720,y:403.2431}).wait(1).to({graphics:mask_graphics_104,x:720,y:403.3674}).wait(1).to({graphics:mask_graphics_105,x:720,y:403.4586}).wait(1).to({graphics:mask_graphics_106,x:720,y:403.5257}).wait(1).to({graphics:mask_graphics_107,x:720,y:403.5743}).wait(1).to({graphics:mask_graphics_108,x:720,y:403.6083}).wait(1).to({graphics:mask_graphics_109,x:720,y:403.6305}).wait(1).to({graphics:mask_graphics_110,x:720,y:403.6427}).wait(1).to({graphics:mask_graphics_111,x:720,y:395.3}).wait(3));

		// Layer_1
		this.instance = new lib.ClipGroup();
		this.instance.setTransform(721.6,400,1,1,0,0,0,720,400);

		this.shape = new cjs.Shape();
		this.shape.graphics.f("#F2F3F5").s().p("EhwfA+gMAAAh8/MDg/AAAMAAAB8/g");
		this.shape.setTransform(721.6,400);

		var maskedShapeInstanceList = [this.instance,this.shape];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask;
		}

		this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.shape},{t:this.instance}]},96).wait(18));

		// MSK (mask)
		var mask_1 = new cjs.Shape();
		mask_1._off = true;
		var mask_1_graphics_0 = new cjs.Graphics().p("Eg6/AGwIAAtfMB1/AAAIAANfg");
		var mask_1_graphics_58 = new cjs.Graphics().p("EgfvAjIIAAwAMB1/AAAIAAQAg");

		this.timeline.addTween(cjs.Tween.get(mask_1).to({graphics:mask_1_graphics_0,x:726.4,y:398.4}).wait(58).to({graphics:mask_1_graphics_58,x:552,y:224.8129}).wait(56));

		// C
		this.instance_1 = new lib.C_1();
		this.instance_1.setTransform(932.25,302.75);
		this.instance_1._off = true;

		var maskedShapeInstanceList = [this.instance_1];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_1;
		}

		this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(43).to({_off:false},0).wait(1).to({y:313.4428},0).wait(1).to({y:334.8943},0).wait(1).to({y:362.7518},0).wait(1).to({y:376.9717},0).wait(1).to({y:384.8736},0).wait(1).to({y:389.8954},0).wait(1).to({y:393.2706},0).wait(1).to({y:395.5708},0).wait(1).to({y:397.1085},0).wait(1).to({y:398.074},0).wait(1).to({y:398.5922},0).wait(1).to({y:398.75},0).to({_off:true},41).wait(18));

		// msk (mask)
		var mask_2 = new cjs.Shape();
		mask_2._off = true;
		mask_2.graphics.p("Eg6/AGwIAAtfMB1/AAAIAANfg");
		mask_2.setTransform(726.4,398.4);

		// I
		this.instance_2 = new lib.I_1();
		this.instance_2.setTransform(871.25,310.65);
		this.instance_2._off = true;

		var maskedShapeInstanceList = [this.instance_2];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_2;
		}

		this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(37).to({_off:false},0).wait(1).to({y:319.4667},0).wait(1).to({y:336.0449},0).wait(1).to({y:361.002},0).wait(1).to({y:375.5547},0).wait(1).to({y:383.5558},0).wait(1).to({y:388.6431},0).wait(1).to({y:392.1092},0).wait(1).to({y:394.5358},0).wait(1).to({y:396.2342},0).wait(1).to({y:397.3912},0).wait(1).to({y:398.1278},0).wait(1).to({y:398.5274},0).wait(1).to({y:398.65},0).to({_off:true},46).wait(18));

		// MSK (mask)
		var mask_3 = new cjs.Shape();
		mask_3._off = true;
		mask_3.graphics.p("Eg6/AGwIAAtfMB1/AAAIAANfg");
		mask_3.setTransform(726.4,398.4);

		// T
		this.instance_3 = new lib.Tween1("synched",0);
		this.instance_3.setTransform(815.2,248.55);
		this.instance_3._off = true;

		var maskedShapeInstanceList = [this.instance_3];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_3;
		}

		this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(24).to({_off:false},0).wait(1).to({y:259.3535},0).wait(1).to({y:274.0331},0).wait(1).to({y:295.2625},0).wait(1).to({y:326.8938},0).wait(1).to({y:362.8719},0).wait(1).to({y:389.1298},0).wait(1).to({y:406.7481},0).wait(1).to({y:419.3273},0).wait(1).to({y:428.8043},0).wait(1).to({y:436.2023},0).wait(1).to({y:442.1109},0).wait(1).to({y:446.8981},0).wait(1).to({y:450.8084},0).wait(1).to({y:454.0127},0).wait(1).to({y:456.6352},0).wait(1).to({y:458.7689},0).wait(1).to({y:460.4857},0).wait(1).to({y:461.8421},0).wait(1).to({y:462.8834},0).wait(1).to({y:463.6466},0).wait(1).to({y:464.1624},0).wait(1).to({y:464.4563},0).wait(1).to({y:464.55},0).to({_off:true},49).wait(18));

		// msk (mask)
		var mask_4 = new cjs.Shape();
		mask_4._off = true;
		mask_4.graphics.p("Eg6/AGwIAAtfMB1/AAAIAANfg");
		mask_4.setTransform(726.4,398.4);

		// A
		this.instance_4 = new lib.A_1_2();
		this.instance_4.setTransform(742.05,489.95);
		this.instance_4._off = true;

		var maskedShapeInstanceList = [this.instance_4];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_4;
		}

		this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(44).to({_off:false},0).wait(1).to({y:482.3409},0).wait(1).to({y:469.4879},0).wait(1).to({y:447.4558},0).wait(1).to({y:429.4328},0).wait(1).to({y:419.4393},0).wait(1).to({y:413.1575},0).wait(1).to({y:408.838},0).wait(1).to({y:405.73},0).wait(1).to({y:403.4486},0).wait(1).to({y:401.7702},0).wait(1).to({y:400.5534},0).wait(1).to({y:399.7025},0).wait(1).to({y:399.1495},0).wait(1).to({y:398.8447},0).wait(1).to({y:398.75},0).to({_off:true},37).wait(18));

		// mask_idn (mask)
		var mask_5 = new cjs.Shape();
		mask_5._off = true;
		mask_5.graphics.p("EgfvArQIAAxkMB1/AAAIAARkg");
		mask_5.setTransform(552,276.8173);

		// N
		this.instance_5 = new lib.N_1();
		this.instance_5.setTransform(653.75,616.25);
		this.instance_5._off = true;

		var maskedShapeInstanceList = [this.instance_5];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
		}

		this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(14).to({_off:false},0).wait(1).to({y:608.9958},0).wait(1).to({y:600.1171},0).wait(1).to({y:588.9621},0).wait(1).to({y:574.4827},0).wait(1).to({y:555.1786},0).wait(1).to({y:530.5171},0).wait(1).to({y:505.0732},0).wait(1).to({y:484.5209},0).wait(1).to({y:469.113},0).wait(1).to({y:457.3299},0).wait(1).to({y:448.0141},0).wait(1).to({y:440.4418},0).wait(1).to({y:434.1567},0).wait(1).to({y:428.8589},0).wait(1).to({y:424.3421},0).wait(1).to({y:420.4592},0).wait(1).to({y:417.1016},0).wait(1).to({y:414.1868},0).wait(1).to({y:411.6509},0).wait(1).to({y:409.4432},0).wait(1).to({y:407.5228},0).wait(1).to({y:405.8562},0).wait(1).to({y:404.4157},0).wait(1).to({y:403.1779},0).wait(1).to({y:402.1232},0).wait(1).to({y:401.2347},0).wait(1).to({y:400.4979},0).wait(1).to({y:399.9},0).wait(1).to({y:399.43},0).wait(1).to({y:399.0781},0).wait(1).to({y:398.8358},0).wait(1).to({y:398.6954},0).wait(1).to({y:398.65},0).to({_off:true},1).wait(66));

		// A
		this.instance_6 = new lib.A_1();
		this.instance_6.setTransform(566.3,616.35);
		this.instance_6._off = true;

		var maskedShapeInstanceList = [this.instance_6];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
		}

		this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(6).to({_off:false},0).wait(1).to({y:606.4909},0).wait(1).to({y:594.5037},0).wait(1).to({y:579.5702},0).wait(1).to({y:560.3913},0).wait(1).to({y:535.0711},0).wait(1).to({y:502.4372},0).wait(1).to({y:467.1506},0).wait(1).to({y:437.4166},0).wait(1).to({y:414.8574},0).wait(1).to({y:397.6092},0).wait(1).to({y:384.0028},0).wait(1).to({y:372.9626},0).wait(1).to({y:363.808},0).wait(1).to({y:356.0923},0).wait(1).to({y:349.5104},0).wait(1).to({y:343.8451},0).wait(1).to({y:338.9371},0).wait(1).to({y:334.6658},0).wait(1).to({y:330.9379},0).wait(1).to({y:327.6797},0).wait(1).to({y:324.832},0).wait(1).to({y:322.3464},0).wait(1).to({y:320.1829},0).wait(1).to({y:318.3079},0).wait(1).to({y:316.693},0).wait(1).to({y:315.3141},0).wait(1).to({y:314.1502},0).wait(1).to({y:313.1833},0).wait(1).to({y:312.3975},0).wait(1).to({y:311.7789},0).wait(1).to({y:311.3152},0).wait(1).to({y:310.9955},0).wait(1).to({y:310.81},0).wait(1).to({y:310.75},0).to({_off:true},1).wait(73));

		// F
		this.instance_7 = new lib.F_1();
		this.instance_7.setTransform(499.1,616.25);

		var maskedShapeInstanceList = [this.instance_7];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_5;
		}

		this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(1).to({y:603.1285},0).wait(1).to({y:587.0685},0).wait(1).to({y:566.8909},0).wait(1).to({y:540.7003},0).wait(1).to({y:505.7826},0).wait(1).to({y:461.1744},0).wait(1).to({y:415.1508},0).wait(1).to({y:377.9752},0).wait(1).to({y:350.1051},0).wait(1).to({y:328.7915},0).wait(1).to({y:311.9409},0).wait(1).to({y:298.2439},0).wait(1).to({y:286.8754},0).wait(1).to({y:277.2925},0).wait(1).to({y:269.1225},0).wait(1).to({y:262.099},0).wait(1).to({y:256.0256},0).wait(1).to({y:250.7533},0).wait(1).to({y:246.1664},0).wait(1).to({y:242.173},0).wait(1).to({y:238.6993},0).wait(1).to({y:235.6847},0).wait(1).to({y:233.0791},0).wait(1).to({y:230.8402},0).wait(1).to({y:228.9325},0).wait(1).to({y:227.3254},0).wait(1).to({y:225.9925},0).wait(1).to({y:224.911},0).wait(1).to({y:224.0608},0).wait(1).to({y:223.4244},0).wait(1).to({y:222.9861},0).wait(1).to({y:222.7321},0).wait(1).to({y:222.65},0).to({_off:true},1).wait(80));

		// MSK (mask)
		var mask_6 = new cjs.Shape();
		mask_6._off = true;
		mask_6.graphics.p("Eg6/AGwIAAtfMB1/AAAIAANfg");
		mask_6.setTransform(726.4,398.4);

		// N_2
		this.instance_8 = new lib.N_2();
		this.instance_8.setTransform(653.75,486.65);
		this.instance_8._off = true;

		var maskedShapeInstanceList = [this.instance_8];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_6;
		}

		this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(49).to({_off:false},0).wait(1).to({y:479.8741},0).wait(1).to({y:468.903},0).wait(1).to({y:449.9797},0).wait(1).to({y:431.6484},0).wait(1).to({y:421.1129},0).wait(1).to({y:414.5445},0).wait(1).to({y:410.0327},0).wait(1).to({y:406.7667},0).wait(1).to({y:404.339},0).wait(1).to({y:402.5168},0).wait(1).to({y:401.1545},0).wait(1).to({y:400.1547},0).wait(1).to({y:399.4487},0).wait(1).to({y:398.9865},0).wait(1).to({y:398.73},0).wait(1).to({y:398.65},0).to({_off:true},31).wait(18));

		// MSK (mask)
		var mask_7 = new cjs.Shape();
		mask_7._off = true;
		mask_7.graphics.p("Eg6/AGwIAAtfMB1/AAAIAANfg");
		mask_7.setTransform(726.4,398.4);

		// A_2
		this.instance_9 = new lib.A_2();
		this.instance_9.setTransform(566.3,486.75);
		this.instance_9._off = true;

		var maskedShapeInstanceList = [this.instance_9];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_7;
		}

		this.timeline.addTween(cjs.Tween.get(this.instance_9).wait(53).to({_off:false},0).wait(1).to({y:479.9741},0).wait(1).to({y:469.003},0).wait(1).to({y:450.0797},0).wait(1).to({y:431.7484},0).wait(1).to({y:421.2129},0).wait(1).to({y:414.6445},0).wait(1).to({y:410.1327},0).wait(1).to({y:406.8667},0).wait(1).to({y:404.439},0).wait(1).to({y:402.6168},0).wait(1).to({y:401.2545},0).wait(1).to({y:400.2547},0).wait(1).to({y:399.5487},0).wait(1).to({y:399.0865},0).wait(1).to({y:398.83},0).wait(1).to({y:398.75},0).to({_off:true},27).wait(18));

		// MSK (mask)
		var mask_8 = new cjs.Shape();
		mask_8._off = true;
		mask_8.graphics.p("Eg6/AGwIAAtfMB1/AAAIAANfg");
		mask_8.setTransform(726.4,398.4);

		// F_2
		this.instance_10 = new lib.F_2();
		this.instance_10.setTransform(499.1,486.65);
		this.instance_10._off = true;

		var maskedShapeInstanceList = [this.instance_10];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_8;
		}

		this.timeline.addTween(cjs.Tween.get(this.instance_10).wait(58).to({_off:false},0).wait(1).to({y:479.8741},0).wait(1).to({y:468.903},0).wait(1).to({y:449.9797},0).wait(1).to({y:431.6484},0).wait(1).to({y:421.1129},0).wait(1).to({y:414.5445},0).wait(1).to({y:410.0327},0).wait(1).to({y:406.7667},0).wait(1).to({y:404.339},0).wait(1).to({y:402.5168},0).wait(1).to({y:401.1545},0).wait(1).to({y:400.1547},0).wait(1).to({y:399.4487},0).wait(1).to({y:398.9865},0).wait(1).to({y:398.73},0).wait(1).to({y:398.65},0).to({_off:true},22).wait(18));

		// logo
		this.shape_1 = new cjs.Shape();
		this.shape_1.graphics.f("#FFFFFF").s().p("AhkGeQhUggg9g6Qg7g5gjhUQgjhSAAhlQAAhkAkhUQAjhSA9g7QA+g7BQgfQBTggBaAAQBGAAA9AOQA6APAoASQAgANAbARIhwDGIgWgOQgcgNghgKQglgNgtAAQgwAAgpASQgpARgeAfQgeAegSAoQgSApAAAtQAAAtATAqQATAqAeAdQAgAeAqARQAoARAyAAQAxAAAmgMQAngNAYgPQALgGANgIIBvDFQgbASghAPQgoASg8AQQg4AQhMAAQhjAAhUgig");
		this.shape_1.setTransform(932.275,398.75);

		this.shape_2 = new cjs.Shape();
		this.shape_2.graphics.f("#FFFFFF").s().p("AhuGpIAAtRIDdAAIAANRg");
		this.shape_2.setTransform(871.25,398.675);

		this.shape_3 = new cjs.Shape();
		this.shape_3.graphics.f("#FFFFFF").s().p("AhuGpIAAp9IjYAAIAAjUIKNAAIAADUIjYAAIAAJ9g");
		this.shape_3.setTransform(814.075,398.675);

		this.shape_4 = new cjs.Shape();
		this.shape_4.graphics.f("#FFFFFF").s().p("ADcGpIg1iOIlMAAIg2COIjbAAIFAtRIDsAAIFBNRgABfBdIhfj9IheD9IC9AAg");
		this.shape_4.setTransform(742.05,398.775);

		this.shape_5 = new cjs.Shape();
		this.shape_5.graphics.f("#FFFFFF").s().p("ADdGpIl0nAIAAHAIjeAAIAAtRICYAAIF1HCIAAnCIDeAAIAANRg");
		this.shape_5.setTransform(653.775,398.675);

		this.shape_6 = new cjs.Shape();
		this.shape_6.graphics.f("#FFFFFF").s().p("ADbGpIg1iOIlLAAIg2COIjbAAIFAtRIDsAAIFBNRgABeBdIhej9IhfD9IC9AAg");
		this.shape_6.setTransform(566.3,398.775);

		this.shape_7 = new cjs.Shape();
		this.shape_7.graphics.f("#FFFFFF").s().p("AkYGpIAAtRIIxAAIAADUIlTAAIAACUIEHAAIAADEIkHAAIAAElg");
		this.shape_7.setTransform(499.125,398.675);

		this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.shape_7},{t:this.shape_6},{t:this.shape_5},{t:this.shape_4},{t:this.shape_3},{t:this.shape_2},{t:this.shape_1}]},96).wait(18));

		// Line_2
		this.instance_11 = new lib.line_diag_2();
		this.instance_11.setTransform(719.9,399.9,0.675,0.675,-29.858);
		this.instance_11._off = true;

		this.timeline.addTween(cjs.Tween.get(this.instance_11).wait(33).to({_off:false},0).to({scaleX:0.9999,scaleY:0.9999,rotation:-30.2247},8).wait(1).to({scaleX:1,scaleY:1,rotation:-15.148,x:719.9403,y:399.8644},0).wait(1).to({rotation:17.143,x:720.0285,y:399.7996},0).wait(1).to({rotation:51.069,x:720.1161,y:399.7473},0).wait(1).to({rotation:66.9099,x:720.1528,y:399.7271},0).wait(1).to({rotation:75.805,x:720.1719,y:399.7165},0).wait(1).to({rotation:81.4159,x:720.1834,y:399.7099},0).wait(1).to({rotation:85.0978,x:720.1906,y:399.7056},0).wait(1).to({rotation:87.4983,x:720.1953,y:399.7029},0).wait(1).to({rotation:88.979,x:720.1981,y:399.7012},0).wait(1).to({rotation:89.7635,x:720.1996,y:399.7003},0).wait(1).to({rotation:90,x:720.15,y:399.7},0).wait(1).to({rotation:90.1479,x:720.1504,y:399.7004},0).wait(1).to({rotation:90.6338,x:720.1518,y:399.7018},0).wait(1).to({rotation:91.5389,x:720.1543,y:399.7043},0).wait(1).to({rotation:92.9805,x:720.1583,y:399.7083},0).wait(1).to({rotation:95.1369,x:720.1643,y:399.7143},0).wait(1).to({rotation:98.3012,x:720.1731,y:399.7231},0).wait(1).to({rotation:103.0092,x:720.1861,y:399.7361},0).wait(1).to({rotation:110.4171,x:720.2067,y:399.7567},0).wait(1).to({rotation:123.7483,x:720.2437,y:399.7937},0).wait(1).to({rotation:149.8647,x:720.3163,y:399.8663},0).wait(1).to({rotation:169.9755,x:720.3722,y:399.9222},0).wait(1).to({rotation:180,x:720.4,y:399.95},0).to({_off:true},32).wait(18));

		// line_1
		this.instance_12 = new lib.line_diag_1();
		this.instance_12.setTransform(720.45,400,0.6762,0.6762,29.9983,0,0,0.1,0.1);
		this.instance_12._off = true;

		this.timeline.addTween(cjs.Tween.get(this.instance_12).wait(33).to({_off:false},0).to({regY:-0.1,scaleX:0.9999,scaleY:0.9999,rotation:30.2247,x:720.5,y:399.9},8).wait(1).to({regX:0,regY:0,rotation:37.7207,x:720.3025,y:399.9592},0).wait(1).to({scaleX:1,scaleY:1,rotation:53.7757,x:720.2109,y:399.98},0).wait(1).to({rotation:70.6436,x:720.129,y:400.0071},0).wait(1).to({rotation:78.5197,x:720.0951,y:400.0228},0).wait(1).to({rotation:82.9423,x:720.0772,y:400.0326},0).wait(1).to({rotation:85.732,x:720.0662,y:400.0392},0).wait(1).to({rotation:87.5626,x:720.0592,y:400.0438},0).wait(1).to({rotation:88.7562,x:720.0547,y:400.0468},0).wait(1).to({rotation:89.4924,x:720.0519,y:400.0487},0).wait(1).to({rotation:89.8824,x:720.0504,y:400.0497},0).wait(1).to({rotation:90,x:720.15,y:400.2},0).wait(1).to({rotation:90.1479,x:720.1496,y:400.1996},0).wait(1).to({rotation:90.6338,x:720.1482,y:400.1982},0).wait(1).to({rotation:91.5389,x:720.1457,y:400.1957},0).wait(1).to({rotation:92.9805,x:720.1417,y:400.1917},0).wait(1).to({rotation:95.1369,x:720.1357,y:400.1857},0).wait(1).to({rotation:98.3012,x:720.1269,y:400.1769},0).wait(1).to({rotation:103.0092,x:720.1139,y:400.1639},0).wait(1).to({rotation:110.4171,x:720.0933,y:400.1433},0).wait(1).to({rotation:123.7483,x:720.0563,y:400.1063},0).wait(1).to({rotation:149.8647,x:719.9837,y:400.0337},0).wait(1).to({rotation:169.9755,x:719.9278,y:399.9778},0).wait(1).to({rotation:180,x:719.9,y:399.95},0).to({_off:true},32).wait(18));

		// mask_line (mask)
		var mask_9 = new cjs.Shape();
		mask_9._off = true;
		var mask_9_graphics_27 = new cjs.Graphics().p("EA1aAfsQgsgrAAg+QAAg9AsgsQArgsA+AAQA9AAAsAsQAsAsAAA9QAAA+gsArQgsAsg9AAQg+AAgrgsg");
		var mask_9_graphics_28 = new cjs.Graphics().p("EAzMAgCQhNhNAAhuQAAhtBNhNQBOhOBtAAQBtAABOBOQBNBNAABtQAABuhNBNQhOBOhtAAQhtAAhOhOg");
		var mask_9_graphics_29 = new cjs.Graphics().p("EAvnAgmQiEiEAAi7QAAi7CEiEQCEiEC7AAQC7AACECEQCECEAAC7QAAC7iECEQiECEi7AAQi7AAiEiEg");
		var mask_9_graphics_30 = new cjs.Graphics().p("EApcAhjQjjjiAAlBQAAlADjjjQDjjiFAAAQFAAADjDiQDiDjAAFAQAAFBjiDiQjjDjlAAAQlAAAjjjjg");
		var mask_9_graphics_31 = new cjs.Graphics().p("EAjdAieQk+k+AAnBQAAnCE+k+QE+k+HCAAQHCAAE+E+QE+E+AAHCQAAHBk+E+Qk+E/nCAAQnCAAk+k/g");
		var mask_9_graphics_32 = new cjs.Graphics().p("EAgBAjBQlylzAAoMQAAoMFylzQFzlyIMAAQIMAAFyFyQFzFzAAIMQAAIMlzFzQlyFyoMAAQoMAAlzlyg");
		var mask_9_graphics_33 = new cjs.Graphics().p("EAd4AjWQmTmTAAo7QAAo6GTmUQGUmSI6AAQI6AAGTGSQGUGUAAI6QAAI7mUGTQmTGTo6AAQo6AAmUmTg");
		var mask_9_graphics_34 = new cjs.Graphics().p("EAcaAjkQmqmpAApbQAApaGqmqQGqmpJaAAQJaAAGqGpQGqGqAAJaQAAJbmqGpQmqGrpaAAQpaAAmqmrg");
		var mask_9_graphics_35 = new cjs.Graphics().p("EAbWAjvQm7m6AApxQAApyG7m6QG6m5JxAAQJxAAG6G5QG7G6AAJyQAAJxm7G6Qm6G6pxAAQpxAAm6m6g");
		var mask_9_graphics_36 = new cjs.Graphics().p("EAajAj3QnGnGAAqDQAAqCHGnHQHGnFKDAAQKCAAHGHFQHHHHAAKCQAAKDnHHGQnGHGqCAAQqDAAnGnGg");
		var mask_9_graphics_37 = new cjs.Graphics().p("EAZ9Aj9QnPnPAAqQQAAqPHPnQQHPnOKQAAQKPAAHPHOQHQHQAAKPQAAKQnQHPQnPHPqPAAQqQAAnPnPg");
		var mask_9_graphics_38 = new cjs.Graphics().p("EAZgAkBQnWnWAAqZQAAqZHWnWQHXnVKZAAQKYAAHWHVQHXHWAAKZQAAKZnXHWQnWHXqYAAQqZAAnXnXg");
		var mask_9_graphics_39 = new cjs.Graphics().p("EAZLAkEQnbnbAAqfQAAqgHbncQHcnaKgAAQKgAAHbHaQHbHcAAKgQAAKfnbHbQnbHcqgAAQqgAAncncg");
		var mask_9_graphics_40 = new cjs.Graphics().p("EAY9AkHQnfnfAAqlQAAqlHfneQHfneKlAAQKkAAHfHeQHfHeAAKlQAAKlnfHfQnfHfqkAAQqlAAnfnfg");
		var mask_9_graphics_41 = new cjs.Graphics().p("EAYzAkIQnhnhAAqoQAAqoHhngQHhnhKoAAQKpAAHgHhQHiHgAAKoQAAKoniHhQngHhqpAAQqoAAnhnhg");
		var mask_9_graphics_42 = new cjs.Graphics().p("EAYuAkJQniniAAqqQAAqqHiniQHiniKqAAQKqAAHiHiQHjHiAAKqQAAKqnjHiQniHjqqAAQqqAAninjg");
		var mask_9_graphics_43 = new cjs.Graphics().p("EAZLAklQnjnjAAqqQAAqqHjnkQHinhKrAAQKrAAHiHhQHjHkAAKqQAAKqnjHjQniHjqrAAQqrAAninjg");

		this.timeline.addTween(cjs.Tween.get(mask_9).to({graphics:null,x:0,y:0}).wait(27).to({graphics:mask_9_graphics_27,x:367.1608,y:207.1858}).wait(1).to({graphics:mask_9_graphics_28,x:372.7193,y:212.7635}).wait(1).to({graphics:mask_9_graphics_29,x:381.7192,y:221.7946}).wait(1).to({graphics:mask_9_graphics_30,x:397.2426,y:237.3718}).wait(1).to({graphics:mask_9_graphics_31,x:412.2804,y:252.4617}).wait(1).to({graphics:mask_9_graphics_32,x:420.923,y:261.1342}).wait(1).to({graphics:mask_9_graphics_33,x:426.3112,y:266.5411}).wait(1).to({graphics:mask_9_graphics_34,x:430.0124,y:270.255}).wait(1).to({graphics:mask_9_graphics_35,x:432.6916,y:272.9436}).wait(1).to({graphics:mask_9_graphics_36,x:434.6832,y:274.942}).wait(1).to({graphics:mask_9_graphics_37,x:436.1779,y:276.442}).wait(1).to({graphics:mask_9_graphics_38,x:437.2955,y:277.5633}).wait(1).to({graphics:mask_9_graphics_39,x:438.1156,y:278.3864}).wait(1).to({graphics:mask_9_graphics_40,x:438.6948,y:278.9675}).wait(1).to({graphics:mask_9_graphics_41,x:439.074,y:279.348}).wait(1).to({graphics:mask_9_graphics_42,x:439.2843,y:279.5591}).wait(1).to({graphics:mask_9_graphics_43,x:442.4,y:282.4}).wait(71));

		// Line_vert
		this.shape_8 = new cjs.Shape();
		this.shape_8.graphics.f().s("#EB3223").ss(1,0,0,4).p("AAA30MAAAAvp");
		this.shape_8.setTransform(720.65,399.5);
		this.shape_8._off = true;

		var maskedShapeInstanceList = [this.shape_8];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_9;
		}

		this.timeline.addTween(cjs.Tween.get(this.shape_8).wait(27).to({_off:false},0).to({_off:true},69).wait(18));

		// Line_hor
		this.shape_9 = new cjs.Shape();
		this.shape_9.graphics.f().s("#EB3223").ss(1,0,0,4).p("AX1AAMgvpAAA");
		this.shape_9.setTransform(720.15,400);
		this.shape_9._off = true;

		var maskedShapeInstanceList = [this.shape_9];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_9;
		}

		this.timeline.addTween(cjs.Tween.get(this.shape_9).wait(27).to({_off:false},0).to({_off:true},69).wait(18));

		// mask_round (mask)
		var mask_10 = new cjs.Shape();
		mask_10._off = true;
		var mask_10_graphics_0 = new cjs.Graphics().p("Ak9Q+QnCnBAAp9QAAp7HCnDQHAnBJ9AAMAAAAv/Qp9AAnAnCg");
		var mask_10_graphics_1 = new cjs.Graphics().p("EAh9Ag0QlhoRB8pwQB8pwIRlhQIRlhJwB8MgJXAvEQpxh8lhoRg");
		var mask_10_graphics_2 = new cjs.Graphics().p("AdfdWQj0pLD0pMQD0pMJLjzQJMjzJMDzMgSYAsWQpMj0jzpMg");
		var mask_10_graphics_3 = new cjs.Graphics().p("AaDZ9Qh8pwFhoRQFioQJwh9QJvh7ISFgMgarAn7QoRlih8pwg");
		var mask_10_graphics_4 = new cjs.Graphics().p("AXxWxQAAp8HCnCQHCnBJ8gBQJ8ABHCHBMgh8Ah8QnCnCAAp8g");
		var mask_10_graphics_5 = new cjs.Graphics().p("AWvT5QB8pvIRliQIRlgJwB6QJwB9FiIRMgn7AarQlhoSB8pwg");
		var mask_10_graphics_6 = new cjs.Graphics().p("AW/ReQD0pLJMj0QJMj0JLD0QJMDzD0JMMgsXASYQjzpMDzpMg");
		var mask_10_graphics_7 = new cjs.Graphics().p("AYiPkQFhoQJwh8QJwh8IRFhQIRFhB8JxMgvFAJXQh8pwFioSg");
		var mask_10_graphics_8 = new cjs.Graphics().p("AUQfQQAAp8HCnDQHCnBJ8AAQJ8AAHCHBQHCHDAAJ8g");
		var mask_10_graphics_9 = new cjs.Graphics().p("AUsYPQB8pxIRlhQIRlhJwB8QJxB8FhIRQFhIRh8Jwg");
		var mask_10_graphics_10 = new cjs.Graphics().p("AWCReQD0pMJMjzQJLj0JMD0QJMD0D0JLQDzJMjzJMg");
		var mask_10_graphics_11 = new cjs.Graphics().p("AYRLQQFioRJwh8QJwh7IRFgQIRFiB8JwQB8JvlhISg");
		var mask_10_graphics_12 = new cjs.Graphics().p("AbSFzQHCnBJ8AAQJ8AAHCHBQHCHCAAJ8QAAJ8nCHCg");
		var mask_10_graphics_13 = new cjs.Graphics().p("Ae5BUQIRlgJwB8QJwB8FiIQQFhIRh8JwQh8JwoRFig");
		var mask_10_graphics_14 = new cjs.Graphics().p("EAjCgCAQJMjzJMDzQJLDzD0JMQDzJMjzJLQj0JMpMD0g");
		var mask_10_graphics_15 = new cjs.Graphics().p("EAnjgEDQJwh8IRFiQIRFgB8JwQB8JwlhIRQlhIRpxB8g");
		var mask_10_graphics_16 = new cjs.Graphics().p("EAsQgEvQJ8AAHCHBQHCHBAAJ9QAAJ9nCHCQnCHBp8AAg");

		this.timeline.addTween(cjs.Tween.get(mask_10).to({graphics:mask_10_graphics_0,x:643.2,y:400}).wait(1).to({graphics:mask_10_graphics_1,x:374.9829,y:275.3243}).wait(1).to({graphics:mask_10_graphics_2,x:389.3901,y:270.9539}).wait(1).to({graphics:mask_10_graphics_3,x:402.6678,y:263.8569}).wait(1).to({graphics:mask_10_graphics_4,x:414.3058,y:254.3058}).wait(1).to({graphics:mask_10_graphics_5,x:423.8569,y:242.6678}).wait(1).to({graphics:mask_10_graphics_6,x:430.9539,y:229.3901}).wait(1).to({graphics:mask_10_graphics_7,x:435.3243,y:214.9829}).wait(1).to({graphics:mask_10_graphics_8,x:436.8,y:200}).wait(1).to({graphics:mask_10_graphics_9,x:436.9398,y:214.9829}).wait(1).to({graphics:mask_10_graphics_10,x:437.0497,y:229.3901}).wait(1).to({graphics:mask_10_graphics_11,x:436.9547,y:242.6678}).wait(1).to({graphics:mask_10_graphics_12,x:436.8272,y:254.3058}).wait(1).to({graphics:mask_10_graphics_13,x:436.9547,y:263.8569}).wait(1).to({graphics:mask_10_graphics_14,x:437.0497,y:270.9539}).wait(1).to({graphics:mask_10_graphics_15,x:436.9398,y:275.3243}).wait(1).to({graphics:mask_10_graphics_16,x:436.8,y:276.8}).wait(98));

		// ro
		this.shape_10 = new cjs.Shape();
		this.shape_10.graphics.f().s("#EB3223").ss(1,0,0,4).p("Ar3s9QCoAACZBCQCUA/BzBzQBxByA+CVQBBCaAACoQAACphBCaQg+CVhxBzQhzBziUA/QiZBBioAAAr33vQE1AAEZB3QEOB0DSDSQDSDTBzERQB3EaAAE0QAAE1h3EaQhzERjSDTQjSDSkOBzQkZB4k1AA");
		this.shape_10.setTransform(796.15,399.5);

		var maskedShapeInstanceList = [this.shape_10];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_10;
		}

		this.timeline.addTween(cjs.Tween.get(this.shape_10).to({_off:true},96).wait(18));

		// ma_2
		this.shape_11 = new cjs.Shape();
		this.shape_11.graphics.f("#1D1D22").s().p("EgUfAoYMAAAhQvMAo/AAAMAAABQvg");
		this.shape_11.setTransform(851.2,397.6);

		this.timeline.addTween(cjs.Tween.get(this.shape_11).to({_off:true},96).wait(18));

		// mask_round_2 (mask)
		var mask_11 = new cjs.Shape();
		mask_11._off = true;
		var mask_11_graphics_0 = new cjs.Graphics().p("EAsQgEvQJ8AAHCHBQHCHBAAJ9QAAJ9nCHCQnCHBp8AAg");
		var mask_11_graphics_16 = new cjs.Graphics().p("EAsQgEvQJ8AAHCHBQHCHBAAJ9QAAJ9nCHCQnCHBp8AAg");
		var mask_11_graphics_17 = new cjs.Graphics().p("EAnjAqxMAJXgvEQJxB8FhIQQFhIRh8JwQh8JxoRFhQmHEFm8AAQicAAiiggg");
		var mask_11_graphics_18 = new cjs.Graphics().p("EAjCApZMASXgsWQJMDzD0JMQDzJLjzJMQj0JMpLD0QkmB5kmAAQkmABkmh6g");
		var mask_11_graphics_19 = new cjs.Graphics().p("EAe5AnMMAargn6QIRFhB8JwQB8JwlhIRQliIRpwB8QihAhicAAQm8AAmIkGg");
		var mask_11_graphics_20 = new cjs.Graphics().p("EAbSAkOMAh8gh8QHCHCAAJ8QAAJ8nCHCQnCHCp8AAQp8AAnCnCg");
		var mask_11_graphics_21 = new cjs.Graphics().p("EAnjAqxQpwh8lioRMAn7garQFhIRh8JwQh8JwoRFiQmIEGm8AAQibAAiighg");
		var mask_11_graphics_22 = new cjs.Graphics().p("EAjCApZQpMj0j0pMMAsXgSXQDzJMjzJMQj0JLpMD0QkmB5kmAAQkmABklh6g");
		var mask_11_graphics_23 = new cjs.Graphics().p("EAe5AnMQoRlhh8pxMAvFgJXQB8JwlhIRQlhIRpxB8QiiAgicAAQm8AAmHkFg");
		var mask_11_graphics_24 = new cjs.Graphics().p("EAbSAkPQnCnCAAp9MAwAAAAQAAJ9nCHCQnCHBp8AAQp9AAnBnBg");
		var mask_11_graphics_25 = new cjs.Graphics().p("EAnNAqoQpsiOlToaQlSobCOpsMAuzAKrQiOJtobFSQl7DvmkAAQiwAAi4gqg");
		var mask_11_graphics_26 = new cjs.Graphics().p("EAjCAo2Qo+kVjSpYQjSpYEUo+MArQAU1QkUI+pZDSQkDBaj+AAQlPAAlFicg");
		var mask_11_graphics_27 = new cjs.Graphics().p("EAf6AmAQnymNhHp4QhHp4GNnxMAlhAd7QmMHyp5BHQhgALhdAAQoGAAmmlRg");
		var mask_11_graphics_28 = new cjs.Graphics().p("EAuFArGQp4hHmNnyQmNnyBHp4QBIp4HxmNMAd8AliQmmFRoHAAQhdAAhggLg");
		var mask_11_graphics_29 = new cjs.Graphics().p("EArHAp4QpYjSkUo+QkUo9DRpZQDTpYI9kTMAU1ArPQlFCclPAAQj+AAkEhag");
		var mask_11_graphics_30 = new cjs.Graphics().p("EAozAnjQoalSiNptQiOpsFSobQFToZJsiOMAKsAuyQi3AqiwAAQmlAAl8jvg");
		var mask_11_graphics_31 = new cjs.Graphics().p("Ak9Q+QnCnBAAp9QAAp7HCnDQHAnBJ9AAMAAAAv/Qp9AAnAnCg");

		this.timeline.addTween(cjs.Tween.get(mask_11).to({graphics:mask_11_graphics_0,x:436.8,y:276.8}).wait(16).to({graphics:mask_11_graphics_16,x:436.8,y:276.8}).wait(1).to({graphics:mask_11_graphics_17,x:436.9394,y:276.9398}).wait(1).to({graphics:mask_11_graphics_18,x:437.0473,y:277.0497}).wait(1).to({graphics:mask_11_graphics_19,x:436.9471,y:276.9547}).wait(1).to({graphics:mask_11_graphics_20,x:436.8095,y:276.8272}).wait(1).to({graphics:mask_11_graphics_21,x:436.9433,y:276.9547}).wait(1).to({graphics:mask_11_graphics_22,x:437.0439,y:277.0497}).wait(1).to({graphics:mask_11_graphics_23,x:436.9381,y:276.9398}).wait(1).to({graphics:mask_11_graphics_24,x:436.8,y:276.8}).wait(1).to({graphics:mask_11_graphics_25,x:434.8745,y:276.9648}).wait(1).to({graphics:mask_11_graphics_26,x:429.1944,y:277.039}).wait(1).to({graphics:mask_11_graphics_27,x:420.0447,y:276.8697}).wait(1).to({graphics:mask_11_graphics_28,x:407.884,y:276.8668}).wait(1).to({graphics:mask_11_graphics_29,x:393.3223,y:277.0352}).wait(1).to({graphics:mask_11_graphics_30,x:377.0896,y:276.9631}).wait(1).to({graphics:mask_11_graphics_31,x:643.2,y:400}).wait(83));

		// roror
		this.shape_12 = new cjs.Shape();
		this.shape_12.graphics.f().s("#EB3223").ss(1,0,0,4).p("AM5AAQAACphBCaQg+CVhyBzQhzBziUA/QiZBBioAAQinAAiZhBQiUg/hzhzQhyhzg+iVQhBiaAAipQAAioBBiaQA+iVByhyQBzhzCUg/QCZhCCnAAQCoAACZBCQCUA/BzBzQByByA+CVQBBCaAACog");
		this.shape_12.setTransform(720.15,399.5);

		this.shape_13 = new cjs.Shape();
		this.shape_13.graphics.f().s("#EB3223").ss(1,0,0,4).p("AXrAAQAAE1h3EaQhzERjSDTQjSDSkPBzQkZB4k1AAQkzAAkZh4QkQhzjSjSQjSjThzkRQh3kaAAk1QAAk0B3kaQBzkRDSjTQDSjSEQh0QEZh3EzAAQE1AAEZB3QEPB0DSDSQDSDTBzERQB3EaAAE0g");
		this.shape_13.setTransform(720.15,399.5);

		var maskedShapeInstanceList = [this.shape_12,this.shape_13];

		for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
			maskedShapeInstanceList[shapedInstanceItr].mask = mask_11;
		}

		this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_13},{t:this.shape_12}]}).wait(114));

		// round
		this.shape_14 = new cjs.Shape();
		this.shape_14.graphics.f().s("#EB3223").ss(1,0,0,4).p("AX1AAMgvpAAA");
		this.shape_14.setTransform(720.15,400);

		this.shape_15 = new cjs.Shape();
		this.shape_15.graphics.f().s("#EB3223").ss(1,0,0,4).p("Ae0xxMg9nAjj");
		this.shape_15.setTransform(720.425,399.95);

		this.shape_16 = new cjs.Shape();
		this.shape_16.graphics.f().s("#EB3223").ss(1,0,0,4).p("Ae0RyMg9ngjj");
		this.shape_16.setTransform(719.925,399.95);

		this.shape_17 = new cjs.Shape();
		this.shape_17.graphics.f().s("#EB3223").ss(1,0,0,4).p("AAA30MAAAAvp");
		this.shape_17.setTransform(720.65,399.5);

		this.shape_18 = new cjs.Shape();
		this.shape_18.graphics.f().s("#EB3223").ss(1,0,0,4).p("AM5AAQAACphBCaQg+CVhyBzQhzBziUA/QiZBBioAAQinAAiZhBQiUg/hzhzQhyhzg+iVQhBiaAAipQAAioBBiaQA+iVByhyQBzhzCUg/QCZhCCnAAQCoAACZBCQCUA/BzBzQByByA+CVQBBCaAACog");
		this.shape_18.setTransform(720.15,399.5);

		this.shape_19 = new cjs.Shape();
		this.shape_19.graphics.f().s("#EB3223").ss(1,0,0,4).p("AXrAAQAAE1h3EaQhzERjSDTQjSDSkPBzQkZB4k1AAQkzAAkZh4QkQhzjSjSQjSjThzkRQh3kaAAk1QAAk0B3kaQBzkRDSjTQDSjSEQh0QEZh3EzAAQE1AAEZB3QEPB0DSDSQDSDTBzERQB3EaAAE0g");
		this.shape_19.setTransform(720.15,399.5);

		this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.shape_19},{t:this.shape_18},{t:this.shape_17},{t:this.shape_16},{t:this.shape_15},{t:this.shape_14}]},96).wait(18));

		// bg
		this.shape_20 = new cjs.Shape();
		this.shape_20.graphics.f("#1D1D22").s().p("EhwfA+gMAAAh8/MDg/AAAMAAAB8/g");
		this.shape_20.setTransform(720,400);

		this.timeline.addTween(cjs.Tween.get(this.shape_20).wait(114));

		this._renderFirstFrame();

	}).prototype = p = new lib.AnMovieClip();
	p.nominalBounds = new cjs.Rectangle(720,400,721.5999999999999,400);
// library properties:
	lib.properties = {
		id: 'B8E4F8D7ACE64AC297F6887B5CE714D7',
		width: 1440,
		height: 800,
		fps: 25,
		color: "#1D1C22",
		opacity: 1.00,
		manifest: [],
		preloads: []
	};


	(lib.Stage = function(canvas) {
		createjs.Stage.call(this, canvas);
	}).prototype = p = new createjs.Stage();

	p.setAutoPlay = function(autoPlay) {
		this.tickEnabled = autoPlay;
	}
	p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
	p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
	p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
	p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

	p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

	an.bootcompsLoaded = an.bootcompsLoaded || [];
	if(!an.bootstrapListeners) {
		an.bootstrapListeners=[];
	}

	an.bootstrapCallback=function(fnCallback) {
		an.bootstrapListeners.push(fnCallback);
		if(an.bootcompsLoaded.length > 0) {
			for(var i=0; i<an.bootcompsLoaded.length; ++i) {
				fnCallback(an.bootcompsLoaded[i]);
			}
		}
	};

	an.compositions = an.compositions || {};
	an.compositions['B8E4F8D7ACE64AC297F6887B5CE714D7'] = {
		getStage: function() { return exportRoot.stage; },
		getLibrary: function() { return lib; },
		getSpriteSheet: function() { return ss; },
		getImages: function() { return img; }
	};

	an.compositionLoaded = function(id) {
		an.bootcompsLoaded.push(id);
		for(var j=0; j<an.bootstrapListeners.length; j++) {
			an.bootstrapListeners[j](id);
		}
	}

	an.getComposition = function(id) {
		return an.compositions[id];
	}


	an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {
		var lastW, lastH, lastS=1;
		window.addEventListener('resize', resizeCanvas);
		resizeCanvas();
		function resizeCanvas() {
			var w = lib.properties.width, h = lib.properties.height;
			var iw = window.innerWidth, ih=window.innerHeight;
			var pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;
			if(isResp) {
				if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {
					sRatio = lastS;
				}
				else if(!isScale) {
					if(iw<w || ih<h)
						sRatio = Math.min(xRatio, yRatio);
				}
				else if(scaleType==1) {
					sRatio = Math.min(xRatio, yRatio);
				}
				else if(scaleType==2) {
					sRatio = Math.max(xRatio, yRatio);
				}
			}
			domContainers[0].width = w * pRatio * sRatio;
			domContainers[0].height = h * pRatio * sRatio;
			domContainers.forEach(function(container) {
				container.style.width = w * sRatio + 'px';
				container.style.height = h * sRatio + 'px';
			});
			an.stage.scaleX = pRatio*sRatio;
			an.stage.scaleY = pRatio*sRatio;
			lastW = iw; lastH = ih; lastS = sRatio;
			an.stage.tickOnUpdate = false;
			an.stage.update();
			an.stage.tickOnUpdate = true;
		}
	}
	an.handleSoundStreamOnTick = function(event) {
		if(!event.paused){
			var stageChild = an.stage.getChildAt(0);
			if(!stageChild.paused){
				stageChild.syncStreamSounds();
			}
		}
	}

	return an;
}