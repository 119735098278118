const LANGUAGE_TEXT = {
    contacts: {
        title: ['Write us'],
        address: [''],
    },
    process: {
        line: {
            research: [['rese', 'arch']],
            strategy: [['stra', 'tegy']],
            tactics: [['tac', 'tics']],
            implementation: [['execu', 'tion']],
        },
        info: {
            research: [[
                'qualitative auditory research',
                'cjm, ejm and cx research',
                'forecasting and trendwatching',
            ]],
            strategy: [[
                'brand strategy',
                'hr strategy',
                'cx strategy',
            ]],
            tactics: [[
                'advertising campaigns',
                'brand experience',
                'hr communication',
                'new products and services',
            ]
            ],
            implementation: [[
                'marketing department transformation',
                'activation workshops',
                'development of kpi/okr system',
                'development of cx standards and guidelines',
                'service design',
            ]
            ],
        }
    },
    about: {
        title: [
            [
                'we turn',
                'brands',
                'into',
                'long term',
                'competitive',
                'advantage',
            ],
        ]
    },
    menu: {
        about: ['we'],
        clients: ['clients'],
        process: ['process'],
        contacts: ['contacts'],
    }
}


export function setAllTexts(language) {
    const langIndex = language

    setMenuLanguage(langIndex)
    setContactsLanguage(langIndex)
    setProcessLanguage(langIndex)
    setAboutLanguage(langIndex)
}

function setMenuLanguage(langIndex) {
    const items = ['about', 'clients', 'process', 'contacts']

    items.map(el => {
        const htmlEl = document.querySelector(`#nav-${el}`);
        const titleMenu = document.querySelector(`#main-nav-title-${el}`)

        htmlEl.innerHTML = LANGUAGE_TEXT.menu[el][langIndex]
        titleMenu.innerHTML = LANGUAGE_TEXT.menu[el][langIndex]
    })
}

function setContactsLanguage(langIndex) {
    const title = document.querySelector('#lang-contacts')
    const address = document.querySelector('.address')

    title.innerHTML = LANGUAGE_TEXT.contacts.title[langIndex]
    address.innerHTML = LANGUAGE_TEXT.contacts.address[langIndex]
}

function setProcessLanguage(langIndex) {
    const items = ['research', 'strategy', 'tactics', 'implementation',]

    items.map(item => {
        const lineTop = document.querySelector(`#dot-${item} .dot-text .top`)
        const lineBottom = document.querySelector(`#dot-${item} .dot-text .bottom`)

        console.log(item, langIndex)

        lineTop.innerHTML = LANGUAGE_TEXT.process.line[item][langIndex][0]
        lineBottom.innerHTML = LANGUAGE_TEXT.process.line[item][langIndex][1]

        const info = gsap.utils.toArray(`#text-${item} .subtitle .title-line-text`)

        info.map((infoItem, index) => {
            infoItem.innerHTML = LANGUAGE_TEXT.process.info[item][langIndex][index]
        })

        const infoBlock = gsap.utils.toArray(`.process-text .text-${item} .subtitle .title-line-text`)

        infoBlock.map((infoItem, index) => {
            infoItem.innerHTML = LANGUAGE_TEXT.process.info[item][langIndex][index]
        })
    })
}

function setAboutLanguage(langIndex) {
    const titleLines = gsap.utils.toArray(`.title .title-line .title-line-text`)

    titleLines.map((title, index) => {
        title.innerHTML = LANGUAGE_TEXT.about.title[langIndex][index]
    })

}

