export function getHtmlElements() {
    const box = document.querySelector("#process-circle")

    const panelHome = document.querySelector('#panel-home')
    const panelAbout = document.querySelector('#panel-about')
    const panelClients = document.querySelector('#panel-clients')
    const panelProcess = document.querySelector('#panel-process')
    const panelContacts = document.querySelector('#panel-contacts')

    const menu = document.querySelector('.site-header')

    // const menuAbout = document.querySelector('#nav-about')
    // const menuClients = document.querySelector('#nav-clients')
    // const menuProcess = document.querySelector('#nav-process')
    // const menuContacts = document.querySelector('#nav-contacts')
    const menuTitle = document.querySelector('#main-nav-title')


    return {
        box,
        panelHome,
        panelAbout,
        panelClients,
        panelProcess,
        panelContacts,
        // underline,
        menu,
        // clipMenu,
        // menuAbout,
        // menuClients,
        // menuProcess,
        // menuContacts,
        // clip,
        menuTitle
    }
}