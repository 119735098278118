import {fanaticAnimationHome} from "./fantastic_1";
import {getMobileForHomeAnimation} from "../utils/utils";
import {fanaticAnimationHomeMobile} from "./fantastic_mob_1";

export const initHomeAnimation = () => {
    const isMobile = getMobileForHomeAnimation()

    var canvas = document.getElementById("canvas");
    var anim_container = document.getElementById("animation_container");
    var dom_overlay_container = document.getElementById("dom_overlay_container");


    var AdobeAn = isMobile ? fanaticAnimationHomeMobile(createjs, {}) : fanaticAnimationHome(createjs, {});

    handleComplete(AdobeAn, canvas, anim_container, dom_overlay_container, isMobile);
}

function handleComplete(AdobeAn, canvas, anim_container, dom_overlay_container, isMobile) {
    var comp = AdobeAn.getComposition("B8E4F8D7ACE64AC297F6887B5CE714D7");
    var lib = comp.getLibrary();
    var ss = comp.getSpriteSheet();

    var exportRoot = isMobile ? new lib.fantastic_mob_1() : new lib.fantastic_1();

    AdobeAn.stage = new lib.Stage(canvas);
    var fnStartAnimation = function () {
        AdobeAn.stage.addChild(exportRoot);
        createjs.Ticker.framerate = lib.properties.fps;
        createjs.Ticker.addEventListener("tick", AdobeAn.stage);
    }
    AdobeAn.makeResponsive(true, 'both', true, 2, [canvas, anim_container, dom_overlay_container]);
    AdobeAn.compositionLoaded(lib.properties.id);
    fnStartAnimation();
}
