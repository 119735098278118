import {movePanels} from "./movePanels";
import {circleToPage, getDeltaDot, moveProcessElement} from "./moveProcessElements";
import {moveAboutElements} from "./about";
import {moveContactElements} from "./contact";
import {navigationHorizontal} from "./navigationHorizontal";
import {getHtmlElements} from "../utils/getHtmlElements";

export function onHorizontalScroll(container, viewPort, masterTl, sections, navLinks, moveCrossesParams) {
    const delta = getDeltaDot()

    const {
        box,
        panelAbout,
        panelClients,
        panelProcess,
        panelContacts,
        menu,
    } = getHtmlElements()

    const masterTrigger = ScrollTrigger.create({
        trigger: container,
        pin: true,
        snap: 'labels',
        scrub: 0.5,
        start: "top 0",
        end: viewPort * 5,
        invalidateOnRefresh: true,
        toggleActions: 'play reset play reset',
        animation: masterTl,
    })


    navigationHorizontal(navLinks, masterTl, viewPort)

    masterTl.add(gsap.timeline().to('.process-text', { visibility: 'hidden'}))


    masterTl.addLabel("moveAbout")
    masterTl.add(movePanels(viewPort, 1));
    masterTl.addLabel("moveClients")
    masterTl.add(movePanels(viewPort, 2));
    masterTl.addLabel("moveProcess")
    masterTl.add(movePanels(viewPort, 3));
    masterTl.addLabel("moveResearch")
    masterTl.add(moveProcessElement(viewPort, delta, box, 1));
    masterTl.addLabel("moveStrategy")
    masterTl.add(moveProcessElement(viewPort, delta, box, 2));
    masterTl.addLabel("moveTactic")
    masterTl.add(moveProcessElement(viewPort, delta, box, 3));
    masterTl.addLabel("moveImplementation")
    masterTl.add(moveProcessElement(viewPort, delta, box, 4));
    masterTl.addLabel("circleToPage")
    masterTl.add(circleToPage(viewPort, box))
    masterTl.addLabel("moveContact")
    masterTl.add(movePanels(viewPort, 4), '<');
    masterTl.addLabel("end")

    ScrollTrigger.create({
        trigger: panelAbout,
        start: viewPort * 0.5,
        end: viewPort * 1.5,
        toggleClass: {targets: [menu], className: "about"},
        toggleActions: 'play none play reset',
        animation: moveAboutElements(moveCrossesParams),
    })

    ScrollTrigger.create({
        trigger: panelClients,
        start: viewPort * 1.5,
        end: viewPort * 2,
        toggleClass: {targets: [menu], className: "clients"},
    })

    ScrollTrigger.create({
        trigger: panelProcess,
        start: viewPort * 2,
        end: viewPort * 4.5,
        toggleClass: {targets: [menu], className: "process"},
    })

    ScrollTrigger.create({
        trigger: panelContacts,
        start: viewPort * 4.5,
        end: viewPort * 6,
        toggleActions: 'play resume play reset',
        toggleClass: {targets: [menu], className: "contacts"},
        animation: moveContactElements(),
    })
}
