export function textLineAnimation(selector) {
    const lineHeight = document.querySelector(selector).offsetHeight;
    const elements = document.querySelectorAll(selector);
    const tl = gsap.timeline();

    elements.forEach(element => {
        const animation = gsap.from(element, {y: lineHeight, duration: 1, ease: 'power3.out'});
        tl.add(animation, '>-0.7');
    })

    return tl
}
