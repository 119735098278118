export function getMobileStatus() {
    const viewPortWidth = document.body.clientWidth;
    return (viewPortWidth < 1120);
}

export function getMobileForHomeAnimation() {
    const viewPortWidth = document.body.clientWidth;
    return (viewPortWidth < 450);
}

export function getContainerWidth(sections) {
    let containerWidth = 0;
    sections.forEach((section) => {
        containerWidth += section.offsetWidth;
    });

    return containerWidth
}
