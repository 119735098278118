import {getGroup} from "./getLineData";


const strokeParams = {strokeDashoffset: 0, duration: 0.4, ease: 'none'}
const circleParams = {scale: 0, transformOrigin: "center center", duration: 0.4, ease: 'none'}

export function moveGeometryAbout() {
    const master = gsap.timeline()
    const g1 = moveGroup1()
    const g2 = moveGroup2()
    const g3 = moveGroup3()
    const g4 = moveGroup4()
    const g5 = moveGroup5()

    master.add(g1)
    master.add(g2, 0)
    master.add(g3)
    master.add(g4)
    master.add(g5, '<')

    return master
}

function moveGroup1() {
    const {lines, circles} = getGroup("#geometry-1 .group-1")

    return gsap.timeline()
        .to(lines[0], strokeParams, 0)
        .to(lines[1], strokeParams, 0)
        .from(circles[0], circleParams, '>')
}

function moveGroup2() {
    const {lines, circles} = getGroup("#geometry-1 .group-2")

    return gsap.timeline()
        .to(lines[0], strokeParams, 0)
        .to(lines[1], strokeParams, '>')
        .to(lines[2], {strokeDashoffset: 0, duration: 0.5, ease: 'none'}, 0)
        .from(circles[0], circleParams, '>')
}

function moveGroup3() {
    const {lines, circles} = getGroup("#geometry-1 .group-3")

    return gsap.timeline()
        .to(lines[0], strokeParams, 0)
        .to(lines[1], strokeParams, 0)
        .from(circles[0], circleParams, '>')
}

function moveGroup4() {
    const {lines, circles} = getGroup("#geometry-1 .group-4")

    return gsap.timeline()
        .to(lines[0], strokeParams, 0)
        .to(lines[1], strokeParams, '>')
        .to(lines[2], strokeParams, 0)
        .from(circles[0], circleParams, '>')
        .from(circles[1], circleParams, '<')
}

function moveGroup5() {
    const {lines, circles} = getGroup("#geometry-1 .group-5")

    return gsap.timeline()
        .to(lines[0], strokeParams, 0)
        .from(circles[0], circleParams, '>')
}