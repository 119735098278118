import {getMobileStatus} from "./scripts/utils/utils";
import {onHorizontalScroll} from "./scripts/animation/horizontalScroll";
import {initHomeAnimation} from "./scripts/homePageAnimation/home.index";
import {onVerticalScroll} from "./scripts/animation/verticalScroll";
import {setAllTexts} from "./scripts/animation/language";

document.addEventListener("DOMContentLoaded", init);

function init() {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    setAllTexts(0)

    let isMobile = getMobileStatus();

    initHomeAnimation()
    onDocumentReady(isMobile)
    showMainMenu()
}

function onDocumentReady(isMobile) {
    const container = document.querySelector(".container")

    const viewPort = document.documentElement.clientWidth;
    const heightPort = document.documentElement.clientHeight;

    const sections = gsap.utils.toArray("section");
    const navLinks = gsap.utils.toArray(".site-header a")

    const masterTl = gsap.timeline()

    const moveCrossesParams = {scale: 0, duration: 1, ease: 'back'}

    Ticker()
    if (isMobile) {
        onVerticalScroll( moveCrossesParams, viewPort, heightPort)
    } else {
        onHorizontalScroll(container, viewPort, masterTl, sections, navLinks, moveCrossesParams)
    }
}

function showMainMenu() {
    const mainMenu = document.querySelector('.site-header')

    setTimeout(() => {
        mainMenu.classList.remove('hide')
    }, 5000)
}


function Ticker() {
    const boxWidth = 300;
    const totalWidth = boxWidth * 8;  //  * n of boxes
    const no01 = document.querySelectorAll("#no01 .box");
    const dirFromLeft = "+=" + totalWidth;
    const dirFromRight = "-=" + totalWidth;

    const mod = gsap.utils.wrap(0, totalWidth);

    function marquee(which, time, direction){
        gsap.set(which, {
            x:function(i) {
                return i * boxWidth;
            }
        });

        return gsap.timeline()
            .to(which, {
                x: direction,
                modifiers: {
                    x: x => mod(parseFloat(x)) + "px"
                },
                duration: time, ease: 'none',
                repeat: -1,
            })
    }

    gsap.timeline()
        .add(marquee(no01, 15, dirFromLeft), 1);
}
