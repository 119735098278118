import {moveGeometryAbout} from "./geometry/moveGeometryAbout";
import {textLineAnimation} from "./textTitle";


function moveCrosses(moveCrossesParams) {
    const cross0 = document.querySelector('#cross-0')
    const cross1 = document.querySelector('#cross-1')

    return gsap.timeline()
        .from(cross0, moveCrossesParams, 1)
        .from(cross1, moveCrossesParams, 2)
}

export function moveAboutElements(moveCrossesParams) {
    return gsap.timeline()
        .add(moveCrosses(moveCrossesParams), 0)
        .add(moveGeometryAbout(), 0)
        .add(textLineAnimation('#panel-about .title-line-text'), 0)
}