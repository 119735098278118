export function navigationVertical() {

    const menuIcon = document.querySelector('#nav-icon3')
    const siteHeader = document.querySelector('.site-header')
    const menuBlock = document.querySelector('.main-nav')

    menuIcon.addEventListener('click', (e) => {
        e.preventDefault();
        menuIcon.classList.toggle('open')
        menuBlock.classList.toggle('open')
        siteHeader.classList.toggle('open')
    })

    const menuLinks = gsap.utils.toArray('.main-nav a')

    menuLinks.map((link) => {
        link.addEventListener('click', ev => {

            menuIcon.classList.toggle('open')
            menuBlock.classList.toggle('open')
            siteHeader.classList.toggle('open')
        })
    })
}