import {navigationVertical} from "./navigationVertical";
import {moveAboutElements} from "./about";
import {moveContactElements} from "./contact";
import {getHtmlElements} from "../utils/getHtmlElements";
import {getCircleSizeByViewPort} from "../utils/getCircleSizeByViewPort";

export function onVerticalScroll(moveCrossesParams, viewPort, heightPort) {
    navigationVertical()


    const {
        box,
        panelAbout,
        panelClients,
        panelProcess,
        panelContacts,
        menu,
    } = getHtmlElements()


    const deltaV = getDeltaDotVertical()
    const {circle, deltaY, textScale, paddingTop, deltaYTop} = getCircleSizeByViewPort(viewPort)

    const lastPoint = panelProcess.offsetHeight;

    // console.log('circle', circle, 'viewPort', viewPort, 'deltaV', deltaV)

    ScrollTrigger.create({
        trigger: panelAbout,
        start: 'top top+=100px',
        end: 'bottom top+=100px',
        toggleActions: 'play reset play reset',
        toggleClass: {targets: [menu], className: "about"},
        animation: moveAboutElements(moveCrossesParams)
    })

    ScrollTrigger.create({
        trigger: panelClients,
        start: 'top top+=100px',
        end: 'bottom top+=100px',
        toggleClass: {targets: [menu], className: "clients"},
    })

    ScrollTrigger.create({
        trigger: panelProcess,
        start: 'top top+=100px',
        end: 'bottom top+=110px',
        toggleClass: {targets: [menu], className: "process"},
    })

    ScrollTrigger.create({
        trigger: panelContacts,
        start: 'top top+=100px',
        toggleActions: 'play none play reset',
        toggleClass: {targets: [menu], className: "contacts"},
        animation: moveContactElements(moveCrossesParams),
    })

    const animProps = {box, deltaV, deltaY, textScale, deltaYTop, viewPort}
    const toggleActionsAnimate = 'play none none reverse';


    ScrollTrigger.create({
        trigger: '#dot-research',
        start: `top +=${heightPort * 0.75}`,
        end: `top +=${heightPort * 0.25}`,
        scrub: true,
        toggleActions: toggleActionsAnimate,
        animation: animation(animProps, 'research'),
    })

    ScrollTrigger.create({
        trigger: '#dot-strategy',
        start: `top +=${heightPort * 0.75}`,
        end: `top +=${heightPort * 0.25}`,
        scrub: true,
        toggleActions: toggleActionsAnimate,
        animation: animation(animProps, 'strategy'),
    })

    ScrollTrigger.create({
        trigger: '#dot-tactics',
        start: `top +=${heightPort * 0.75}`,
        end: `top +=${heightPort * 0.25}`,
        scrub: true,
        toggleActions: toggleActionsAnimate,
        animation: animation(animProps, 'tactics')
    })

    ScrollTrigger.create({
        trigger: '#dot-implementation',
        start: `top +=${heightPort * 0.75}`,
        end: `top +=${heightPort * 0.25}`,
        scrub: true,
        toggleActions: toggleActionsAnimate,
        animation: animation(animProps, 'implementation')
    })

    ScrollTrigger.create({
        trigger: panelContacts,
        start: `top +=${heightPort * 0.75}`,
        end: `top +=${heightPort * 0.25}`,
        scrub: true,
        toggleActions: toggleActionsAnimate,
        animation: animationLast(box, circle, deltaV, lastPoint, viewPort)
    })

}

export function getDeltaDotVertical() {
    const test = gsap.utils.toArray(".dot")

    return test.map(i => {
        return i.offsetTop
    });
}

function animationLast(box, circle, deltaV, lastPoint, viewPort) {
    const tl = gsap.timeline()

    if (viewPort < 375) {
        tl.set(box, {y: deltaV[1] * 4})
    }

    if (viewPort > 375 && viewPort < 768) {
        tl.set(box, {y: deltaV[1] * 4 - 80})
    }

    if (viewPort >= 768 && viewPort < 1024) {
        tl.set(box, {y: deltaV[1] * 4})
    }

    if (viewPort >= 1024 && viewPort < 1119) {
        tl.set(box, {y: deltaV[1] * 4 + 290})
    }

    tl.to(`.subtitle`, {visibility: 'hidden'}, 0)
    tl.to(box, {y: lastPoint + circle})

    return tl
}


function animation(props, dotName) {
    const {box, deltaV, deltaY, textScale, deltaYTop, viewPort} = props
    const tl = gsap.timeline()

    let calc = deltaV[1];

    let dotDelta;


    if (viewPort < 375) {
        if (dotName === 'research') {
            dotDelta = calc
        }

        if (dotName === 'strategy') {
            dotDelta = calc * 2
            tl.set(box, {y: calc})
        }

        if (dotName === 'tactics') {
            dotDelta = calc * 3
            tl.set(box, {y: calc * 2})
        }

        if (dotName === 'implementation') {
            dotDelta = calc * 4
            tl.set(box, {y: calc * 3})
        }
    }


    if (viewPort > 375 && viewPort < 768) {
        if (dotName === 'research') {
            dotDelta = calc - 100
        }

        if (dotName === 'strategy') {
            dotDelta = calc * 2 - 100
            tl.set(box, {y: calc - 100})
        }

        if (dotName === 'tactics') {
            dotDelta = calc * 3 - 100
            tl.set(box, {y: calc * 2 - 100})
        }

        if (dotName === 'implementation') {
            dotDelta = calc * 4 - 100
            tl.set(box, {y: calc * 3 - 100})
        }
    }

    if (viewPort >= 768 && viewPort < 1024) {
        if (dotName === 'research') {
            dotDelta = calc
        }

        if (dotName === 'strategy') {
            dotDelta = calc * 2
            tl.set(box, {y: calc})
        }

        if (dotName === 'tactics') {
            dotDelta = calc * 3
            tl.set(box, {y: calc * 2})
        }

        if (dotName === 'implementation') {
            dotDelta = calc * 4
            tl.set(box, {y: calc * 3})
        }
    }

    if (viewPort >= 1024 && viewPort < 1119) {
        let over = 290

        if (dotName === 'research') {
            dotDelta = calc + over
        }

        if (dotName === 'strategy') {
            dotDelta = calc * 2 + over
            tl.set(box, {y: calc + over})
        }

        if (dotName === 'tactics') {
            dotDelta = calc * 3 + over
            tl.set(box, {y: calc * 2 + over})
        }

        if (dotName === 'implementation') {
            dotDelta = calc * 4 + over
            tl.set(box, {y: calc * 3 + over})
        }
    }

    const lineProps = {
        box,
        name: dotName,
        delta: dotDelta,
        scale: textScale,
        scaleY: deltaY,
        scaleYTop: deltaYTop
    }

    const tlDot = makeTextLineAnimation(lineProps)
    tl.add(tlDot, 0)

    return tl
}


function makeTextLineAnimation({box, name, delta, scale, scaleY, scaleYTop}) {

    const slip = document.querySelector(`#dot-${name} .dot-text .top`).offsetWidth;
    const tl = gsap.timeline()

    tl
        .to(box, {y: delta})
        .to(`.subtitle`, {visibility: 'hidden'}, 0)
        .to(`#dot-${name} .dot-text .top`, {scale: scale, y: scaleYTop}, 0)
        .to(`#dot-${name} .dot-text .bottom`, {y: scaleY, x: -slip, scale: scale}, 0)
        .to(`#dot-${name} #text-${name} .subtitle`, {visibility: 'visible'}, '>')

    return tl
}