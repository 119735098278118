export const getCircleSizeByViewPort = (viewPort) => {
    let circle;
    let textScale;
    let deltaY;
    let deltaYTop;
    let paddingTop;

    if(viewPort >= 319 && viewPort < 375) {
        circle = 457;
        textScale = 1.5;
        deltaY = 45;
        deltaYTop = 5;
        paddingTop = 90;

    } else if (viewPort >= 375 && viewPort < 480) {
        circle = 503;
        textScale = 1.5;
        deltaY = 40;
        deltaYTop = 0;
        paddingTop = 0;

    } else if (viewPort >= 480 && viewPort < 576) {
        circle = 624;
        textScale = 1.5;
        deltaY = 45;
        deltaYTop = 0;
        paddingTop = 0;

    } else if (viewPort >= 576 && viewPort < 768) {
        circle = 656;
        textScale = 2.0;
        deltaY = 82;
        deltaYTop = 28;
        paddingTop = 0;

    } else if (viewPort >= 768 && viewPort < 1024) {
        circle = 720;
        textScale = 3.0;
        deltaY = 102;
        deltaYTop = 28;
        paddingTop = 0;

    } else if (viewPort >= 1024 && viewPort < 1120) {
        circle = 960;
        textScale = 5.0;
        deltaY = 360;
        deltaYTop = 250;
        paddingTop = 0;

    } else if (viewPort >= 1120 && viewPort < 1280) {
        circle = 354;
        textScale = 2.5;
        deltaY = -55;
        deltaYTop = 0;
        paddingTop = 0;

    } else if (viewPort >= 1280 && viewPort < 1440) {
        circle = 430;
        textScale = 2.8;
        deltaY = -65;
        deltaYTop = 0;
        paddingTop = 0;

    } else if (viewPort >= 1440 && viewPort < 1980) {
        circle = 602;
        textScale = 3.0;
        deltaY = -75;
        deltaYTop = 0;
        paddingTop = 0;

    } else {
        circle = 721;
        textScale = 3.5;
        deltaY = -85;
        deltaYTop = 0;
        paddingTop = 0;
    }


    return { circle, textScale, deltaY, paddingTop, deltaYTop }
}