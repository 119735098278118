export const setLineStyle = (node, l) => {
    node.style.strokeDashoffset = l;
    node.style.strokeDasharray = l;
}

export const getGroup = (path) => {
    const group = document.querySelector(path)
    const children = Array.from(group.children)

    const data = children.reduce((acc, el) => {

        if (el.tagName === 'path') {
            const length = el.getTotalLength();
            setLineStyle(el, length)

            acc.lines = [...acc.lines, el]
        }

        if (el.tagName === 'circle') {
            acc.circles = [...acc.circles, el]
        }

        return acc

    }, {lines: [], circles: []})

    return data
}